<script setup>
import {onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import G_LocationType from "@/views/graficas/G_LocationType.vue";
import G_SalesXAgent from "@/views/graficas/G_SalesXAgent.vue";
import G_ProductXTime from "@/views/graficas/G_ProductXTime.vue";
import G_ClientXTime from "@/views/graficas/G_ClientXTime.vue";
import G_AgentXTime from "@/views/graficas/G_AgentXTime.vue";

onMounted(() => {
    setCurrentPageBreadcrumbs("Gráficas", ["Reportes y Gráficas"]);
});
</script>

<template>
    <div class="general">
        <div class="panel">
            <G_LocationType/>
            <G_SalesXAgent/>
        </div>
        <div class="forTime">
            <G_ProductXTime/>
            <G_AgentXTime/>
        </div>
        <div class="forTimeV2">
            <G_ClientXTime/>
        </div>
    </div>
</template>

<style scoped>
    .general{
        display: flex;
        flex-direction: column;
        gap: 5rem;
    }

    .panel{
        display: flex;
        gap: 6rem;
        justify-content: center;
        flex-wrap: wrap;
    }
    .forTime{
        display: flex;
        gap: 2rem;
    }
    .forTimeV2{
        display: flex;
        justify-content: center;
    }
</style>
<script>
import {computed, defineComponent, ref} from "vue";
import Select from "@/components/forms/Select.vue";
import {Form} from "vee-validate";
import * as Validations from "yup";
import InputText from "@/components/forms/InputText.vue";
import InputNumber from "@/components/forms/InputNumber.vue";
import DynamicContentModal from "@/components/modals/general/DynamicContentModal.vue";
import {hideModalById} from "@/core/helpers/dom";
import Swal from "sweetalert2";
import {getMessageError} from "@/core/helpers/messageFromPulmeros";
import SwalMessageService from "@/core/services/SwalMessageService";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
    components: {
        DynamicContentModal,
        InputNumber,
        InputText,
        Select,
        Form,
    },
    setup() {

        const form = ref();
        const formCancel = ref();
        const btnForm = ref();
        const loading = ref(false);
        const messageService = new SwalMessageService();

        const isViewForm = computed(() => { return form.value === "01" })

        const motivo = [
            {id: "01", name: "\"01\" Comprobantes emitidos con errores con relación."},
            {id: "02", name: "\"02\" Comprobantes emitidos con errores sin relación."},
            {id: "03", name: "\"03\" No se llevó a cabo la operación."},
            {id: "04", name: "\"04\" Operación nominativa relacionada en una factura global."}
        ]

        const validation = () =>{
            return form.value === "01";
        }

        const validationSchema = Validations.object().shape({
            motivo: Validations.string().default('').required("Selecciona una opción")
            , folio: Validations.string().default('')
                .test('required-condition', 'Coloque el folio de la factura', function (value) {
                    if (validation()) {
                        return !!value;
                    }
                    return true;
                })
            , rfc: Validations.string().default('').required("Ingrese el rfc del receptor")
            , total: Validations.number().required("Ingrese el total")
        })

        const enviarForm = async (data) => {
            let obj = {
                UUID: "Es un UUID",
                Total: data.total,
                RFC: data.rfc,
                Motivo: data.motivo,
                FolioSus: data.folio,
            }

            ApiService.post("Invoice/CancelBilling", obj).then(res => {
                console.log(res)
            }).catch(reject => {
                getMessageError(reject, messageService);
            });

            messageService.success(`Se cancelo correctamente la factura`);
        }

        const submitForm = async (filters) => {
            resetForm()

            Swal.fire({
                title: '¿Está seguro de cancelar la factura?',
                text: "Se procederá a cancelar la factura para este folio y no podrá revertir la acción",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#000000',
                cancelButtonColor: '#7E829',
                confirmButtonText: 'Si, cancelar',
                cancelButtonText: 'Cancelar',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await enviarForm(filters);
                }
            });

            hideModalById("#CancelBillingModal");
        }

        const resetForm = () => {
            formCancel.value.resetForm();
            form.value = null;
        }

        return {
            formCancel,
            validationSchema,
            submitForm,
            motivo,
            btnForm,
            loading,
            form,
            isViewForm,
        }
    }
})
</script>

<template>
    <section>
        <DynamicContentModal :targetId="'CancelBillingModal'" :width="600">
            <template v-slot:header>
                <div class="mb-5 text-center">
                    <h1 class="mb-3">
                        <span>{{ 'Cancelación de factura' }}</span>
                    </h1>
                </div>
            </template>

            <Form as="el-form" :validation-schema="validationSchema" @submit="submitForm" ref="formCancel">
                <div class="row g-9 mb-8">
                    <div :class="isViewForm ? 'col-md-6' : 'col-md-12'" class="col-xs-12 fv-row">
                        <label>{{ $t('FIELDS.MOTIVO') }}</label>
                        <Select v-model="form" :name="'motivo'" :options="motivo"/>
                    </div>
                    <div v-show="isViewForm" :class="isViewForm ? 'col-md-6' : 'col-md-12'" class="col-xs-12 fv-row">
                        <label>{{ $t('FIELDS.FOLIOSUST') }}</label>
                        <InputText :name="'folio'"/>
                    </div>
                </div>
                <div class="row g-9 mb-8">
                    <div class="col-md-6 col-xs-12 fv-row">
                        <label>{{ $t('FIELDS.RFCRESEPTOR') }}</label>
                        <InputText :name="'rfc'"/>
                    </div>
                    <div class="col-md-6 col-xs-12 fv-row">
                        <label>{{ $t('FIELDS.TOTAL') }}</label>
                        <InputNumber :name="'total'"/>
                    </div>
                </div>
                <div>
                    <button type="submit" class="btn btn-lg btn-primary float-end" ref="btnForm">
                        <span v-if="loading" class="spinner-border spinner-border-sm me-1" role="status"
                              aria-hidden="true"></span>
                        {{ "Cancelar factura" }}
                    </button>
                </div>
            </Form>
        </DynamicContentModal>
    </section>
</template>

<style lang="scss">
.itemControl {
    width: 272px;
}

.largeItemControl {
    width: 553px;
}

.tamano {
    width: 573px !important;
}
</style>

<i18n>
{
    "es": {
        "TITLE": "Cancelación de factura",
        "FIELDS": {
            "MOTIVO": "Motivo de cancelación",
            "RFCRESEPTOR": "RFC del receptor",
            "TOTAL": "Total",
            "FOLIOSUST": "Folio de sustitución",
            "PRODUCT": "Producto",
            "START": "Fecha de inicio",
            "END": "Fecha de fin"
        },
        "PH": {
            "PRODUCT": "Producto",
            "START": "Fecha de inicio",
            "END": "Fecha de fin"
        }
    }
}
</i18n>

import { defineComponent, PropType, ref, watch } from 'vue';
import OptionSelect from "src/models/shared/OptionSelect";

export default defineComponent({
    setup(props, { emit }) {
        const internalValue = ref(null); // Estado interno para v-model
        const key_control = Math.random().toString(36).slice(2, 7);

        // Sincronizar cambios entre el estado interno y v-model
        watch(() => internalValue.value, (newValue) => {
            emit('update:modelValue', newValue);
        });

        const handleChange = (idSelected: any) => {
            emit('change', idSelected);
        };

        const handleClear = () => {
            emit('clear');
        };

        return {
            internalValue,
            key_control,
            handleChange,
            handleClear,
        };
    },
    props: {
        modelValue: {
            type: [String, Number, Array] as PropType<string | number | Array<any>>,
            required: false,
        },
        options: {
            type: Array as PropType<Array<OptionSelect>>,
            required: true,
        },
        placeholder: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['search', 'change', 'clear', 'update:modelValue'],
});


import  { computed, defineComponent, onBeforeUnmount, onMounted, ref, toRefs, watch } from 'vue';
import SelectFilterRemote from "@/components/forms/SelectFilterRemote.vue"
import Select from "@/components/forms/Select.vue"
// import ClientForm from '../../clients/components/form/ClientForm.vue';
// import Drawer from "@/components/shared/Drawer.vue"
import {  Form } from "vee-validate";
import { getModule } from 'vuex-module-decorators';
// import TableSalesToBillModule from '@/store/modules/bill/modules/table-sales-to-bill';
import NewSaleModule from '@/store/modules/sales/modules/new-sales';
import SendToCashModal from "../modals/SendToCashModal.vue";
import SaleTypeModule from '@/store/modules/saleType/modules/new-saletype';
import * as Validations from "yup";
import NewSale from '@/models/sales/NewSale';
import { SalesTypes } from '@/core/enums/sales-types';
import InfoSale from '@/models/sales/InfoSale';
import NewSaleDetail from '@/models/sales/NewSaleDetail';
import toCurrency from "@/core/filters/ToCurrency"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import UpdateSaleDetail from '@/models/sales/UpdateSaleDetail';
import PDFViewer from "@/components/modals/general/PDFViewer.vue";
import { useRouter } from 'vue-router';
import hotkeys from 'hotkeys-js';
import ApiService from "@/core/services/ApiService";

export default defineComponent({
    emits: ['new-cliente'],
    components:{
        Form,
        SelectFilterRemote,
        // ClientForm,
        // Drawer,
        Select,
        SendToCashModal,
        PDFViewer
    },
    props: {
        isActionAdd: {
            type: Number,
            default: SalesTypes.CreateSale
        }
    },
    setup(props, { emit }){
        //Propiedades
        // const showForm = ref(false);
        const titleReport = ref("Recibo de venta")
        const fileName = ref("ReciboVenta")
        const openPDF = ref(HTMLButtonElement);
        const formSale = ref();
        const moduleSales = getModule(NewSaleModule);
        const moduleSalesType = getModule(SaleTypeModule);
        const openModalSendToCash = ref(HTMLButtonElement);
        const saveSale = ref(HTMLButtonElement);
        const isSale = ref(false);
        const router = useRouter();
        const saleId = ref("");
        const action = ref(0);
        const { isActionAdd } = toRefs(props);

        const typeSale = ref(1);

        //Schema
        const validationSchema = Validations.object().shape({
            customerId: Validations.string().nullable().default(null).required().label("customerId"),
            saleTypeId: Validations.string().nullable().default(null).required().label("saleTypeId"),
            // receiver: Validations.string().nullable().default(null).required().label("receiver"),
            // observation: Validations.string().nullable().default(null).required().label("observation")
        });

        //METODOS
        const handleSearchClient = (name: string) => {
            moduleSales.SEARCH_CLIENTS(name);
        }

        const hadlerNewClient = () => {
            emit("new-cliente");
        }

        // const closeContent = (id: string) => {
        //     let actionModule: Promise<any>;

        //     actionModule = moduleSales.SEARCH_CLIENTS('');

        //     actionModule.then((isCorrect) => {
        //         showForm.value = false;
        //         moduleSales.SAVE_CLIENT_SELECTED(id);
        //         formSale.value.setValues({
        //               customerId: id,
        //               saleTypeId: infoSale.value.saleTypeId
        //           });
        //     });

        // }

        const handlerChangeClient = (id: string) => {
            moduleSales.SAVE_CLIENT_SELECTED(id);
        }

        const handleViewRecib = () => {
            titleReport.value = "Recibo de Venta"
            fileName.value = "ReciboVenta"
            let actionModule: Promise<boolean>;

            actionModule = moduleSales.GET_RECEIP_VIEW(
                infoSale.value.id,
            );

            actionModule.then((isCorrect2) => {
                if(isCorrect2){
                    if(openPDF.value) {
                        (openPDF.value as unknown as HTMLButtonElement).click();
                    }
                }
            });

        }

        const handleViewQuote = () =>{
            titleReport.value = "Cotización"
            fileName.value = "cotizacion"
            let actionModule: Promise<boolean>;

            actionModule = moduleSales.GET_QUOTEVIE(
                infoSale.value.id,
            );

            actionModule.then((isCorrect2) => {
                if(isCorrect2){
                    if(openPDF.value) {
                        (openPDF.value as unknown as HTMLButtonElement).click();
                    }
                }
            });
        }

        const handleViewQuoteCompleted = () =>{
            titleReport.value = "Cotización"
            fileName.value = "cotizacion"
            let actionModule: Promise<boolean>;

            actionModule = moduleSales.GET_QUOTEVIE_COMPLETE(
                infoSale.value.id,
            );

            actionModule.then((isCorrect2) => {
                if(isCorrect2){
                    if(openPDF.value) {
                        (openPDF.value as unknown as HTMLButtonElement).click();
                    }
                }
            });
        }

        const submitForm = async (form: any) => {
            console.log("/--------------------------------/")
            const client = clientsCombo.value.find(ir => ir.id === form.customerId);

            if(client?.addresId === undefined){
                let url = `Customer/GetAddress/${form.customerId}`
                const res = await ApiService.query(url, {});
                form.addressId = res.data
            } else{
                form.addressId = client?.addresId;
            }

            form.total = totalSale.value;
            form.subTotal = moduleSales.getSaleDetail.reduce((sum, current) => sum + current.subTotal, 0); //revisar con adolfo
            form.discount = discontSale.value;
            form.tax = taxtSale.value;
            form.receiver = '';
            form.observation = '';
            form.id = infoSale.value.id;

            if(isActionAdd.value == SalesTypes.CreateSale || isActionAdd.value == SalesTypes.CreateQuote){
                var sendDetails = [] as NewSaleDetail[];
                const details = moduleSales.getSaleDetail;

                if(details.length == 0){
                    Swal.fire({
                        text: `Agregue más productos a la ${(!isSale.value ? 'cotización' : 'venta')}`,
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Aceptar",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    }).then(() => {
                        console.log("ok");
                    });
                    return;
                }

                details.forEach(p => {
                    sendDetails.push({
                        productId: p.productId,
                        unitOfMeasurementId: p.unitOfMeasurementId,
                        price: p.price,
                        discount: p.discount,
                        tax: p.tax,
                        amount: p.amount,
                        total: p.total,
                        discountPersent: p.discountPersent,
                        taxPersent: p.taxPersent,
                        subTotal: p.subTotal
                    });
                });

                form.saleDetails = sendDetails;

                Swal.fire({
                    title: `¿Está seguro de crear la ${(!isSale.value ? 'cotización' : 'venta')}?`,
                    html: `
                        <p>Se procederá a crear la ${(!isSale.value ? 'cotización' : 'venta')} con los productos seleccionados</p>
                        <div style="margin-top: 15px;">
                            <input type="checkbox" id="isLocal" style="margin-right: 5px;">
                            <label for="isLocal">¿Es venta foranea?</label>
                        </div>
                    `,
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonColor: '#000000',
                    cancelButtonColor: '#7E829',
                    confirmButtonText: 'Si, crear',
                    cancelButtonText: 'Cancelar'
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Obtener el checkbox y asegurar que es un HTMLInputElement
                        const isLocalCheckbox = document.getElementById('isLocal') as HTMLInputElement;

                        // Capturar el estado del checkbox
                        const isLocal = isLocalCheckbox?.checked === false;

                        // Pasar el estado del checkbox junto con el formulario
                        sendCreateSale({
                            ...form,
                            isLocal: isLocal
                        });
                    }
                });
            }else{
                var sendDetail = [] as UpdateSaleDetail[];
                const details = moduleSales.getSaleDetailAll;

                if(details.length == 0){
                    Swal.fire({
                        text: `Agregue más productos a la ${(!isSale.value ? 'cotización' : 'venta')}`,
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Aceptar",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    }).then(() => {
                        console.log("Agrega mas productos");
                    });
                    return;
                }

                details.forEach(p => {
                    sendDetail.push({
                        id: p.id ?? '',
                        saleId: p.saleId,
                        status: p.status,
                        productId: p.productId,
                        unitOfMeasurementId: p.unitOfMeasurementId,
                        price: p.price,
                        discount: p.discount,
                        tax: p.tax,
                        amount: p.amount,
                        total: p.total,
                        discountPersent: p.discountPersent,
                        taxPersent: p.taxPersent,
                        subTotal: p.subTotal
                    });
                });

                form.saleDetails = sendDetail;

                Swal.fire({
                    title: `¿Está seguro de actualizar la ${(!isSale.value ? 'cotización' : 'venta')}?`,
                    text: `Se procederá a actualizar la ${(!isSale.value ? 'cotización' : 'venta')} con los productos seleccionados`,
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonColor: '#000000',
                    cancelButtonColor: '#7E829',
                    confirmButtonText: 'Si, actualizar',
                    cancelButtonText: 'Cancelar'
                }).then((result) => {
                    if (result.isConfirmed) {
                        updateSale(form);
                    }
                });
            }
        }

        const updateSale = async(form: any) => {
            await moduleSales.UPDATE_SALE(form);

            Swal.fire({
                title: `Se actualizo correcta la ${(!isSale.value ? 'cotización' : 'venta')}`,
                text: ``,
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#000000',
                cancelButtonColor: '#7E829',
                confirmButtonText: 'Aceptar'
            }).then((result) => {
                if(result.isConfirmed) {
                    if(isSale.value){
                        Swal.fire({
                            title: `¿Desea enviar la venta a pagos?`,
                            text: ``,
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonColor: '#000000',
                            cancelButtonColor: '#7E829',
                            confirmButtonText: 'Aceptar',
                            cancelButtonText: 'Cancelar'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                if(openModalSendToCash.value){
                                    saleId.value = form.id;
                                    (openModalSendToCash.value as unknown as HTMLButtonElement).click();
                                }
                            }else{
                                router.back();
                            }
                        });
                    }else{
                        router.back();
                    }
                }
            });
        }

        const sendCreateSale = async (form: NewSale<NewSaleDetail>) => {
            console.log("Hola")
            const response = await moduleSales.SAVE_NEW_SALE(form);


            Swal.fire({
                title: `Se creó la ${(!isSale.value ? 'cotización' : 'venta')} con el siguiente folio: ${response.folio}`,
                text: ``,
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#000000',
                cancelButtonColor: '#7E829',
                confirmButtonText: 'Aceptar'
            }).then((result) => {
                if(result.isConfirmed) {
                    if(isSale.value){
                        saleId.value = response.id;
                        Swal.fire({
                            title: `¿Desea enviar la venta a pagos?`,
                            text: ``,
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonColor: '#000000',
                            cancelButtonColor: '#7E829',
                            confirmButtonText: 'Aceptar',
                            cancelButtonText: 'Cancelar'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                if(openModalSendToCash.value){
                                    saleId.value = response.id;
                                    action.value = 1;
                                    (openModalSendToCash.value as unknown as HTMLButtonElement).click();
                                }
                                // moduleSales.SEN_TO_PAY(response.id);
                            }else{
                                const salesTypes = saleTypes.value.find(ir => ir.id === form.saleTypeId);
                                const isSaleEntrega = salesTypes?.name.includes("Venta Con Entrega a Domicilio") ?? false;
                                if(isSaleEntrega){
                                    Swal.fire({
                                        title: `¿Desea enviar la venta a programación de entrega?`,
                                        text: ``,
                                        icon: 'info',
                                        showCancelButton: true,
                                        confirmButtonColor: '#000000',
                                        cancelButtonColor: '#7E829',
                                        confirmButtonText: 'Aceptar',
                                        cancelButtonText: 'Cancelar'
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            if(openModalSendToCash.value){
                                                saleId.value = response.id;
                                                action.value = 2;
                                                (openModalSendToCash.value as unknown as HTMLButtonElement).click();
                                            }
                                            // if(openModalSendToCash.value){
                                            //       saleId.value = response.id;
                                            //       (openModalSendToCash.value as unknown as HTMLButtonElement).click();
                                            //   }
                                        }else{
                                            router.back();
                                        }
                                    });
                                }else{
                                    router.back();
                                }
                            }
                        });
                    }else{
                        router.back();
                    }
                }
            });
        }

        const searchClient = () => {
            console.log("buscar cliente");
        }

        onMounted(() => {
            hotkeys('alt+g', (event, handler) => {
                if(saveSale.value){
                    (saveSale.value as unknown as HTMLButtonElement).click();
                }
            });
        });

        onBeforeUnmount(() => {
            hotkeys.unbind('alt+g');
        });


        const clientsCombo = computed(() => moduleSales.getClientOptions);
        const loadingClientCombo = computed(() => moduleSales.loadingClients);
        const importSale = computed(() => moduleSales.getImportSale);
        const discontSale = computed(() => moduleSales.getDiscontSale);
        const totalSale = computed(() => moduleSales.getTotalSale);
        const taxtSale = computed(() => moduleSales.getTaxSale);
        const saleTypes = computed(() => moduleSalesType.getSaleTypeOptions);
        const infoSale = computed(() => moduleSales.getValuesForm);
        const pdf = computed(() => moduleSales.getbase64PDF);

        watch(() => moduleSales.getValuesForm, (currentValue: InfoSale) => {//para usar el watch hay que hacerlo con base a un getter
            if(currentValue && currentValue.id != ''){

                moduleSales.SEARCH_CLIENTS("");
                const salesTypes = saleTypes.value.find(ir => ir.id === currentValue.saleTypeId);

                isSale.value = !salesTypes?.name.includes("Cotización") ?? true;

                formSale.value.setValues({
                    customerId: currentValue.customerId,
                    saleTypeId: currentValue.saleTypeId
                    // observation: currentValue.observation,
                    // receiver: currentValue.receiver
                });
            }
        });

        return {
            submitForm,
            searchClient,
            hadlerNewClient,
            //  closeContent,
            //  showForm
            importSale
            , totalSale
            , discontSale
            , clientsCombo
            , loadingClientCombo
            , validationSchema
            , typeSale
            , taxtSale
            , SalesTypes
            , formSale
            , saleTypes
            , isSale
            , saleId
            , action
            , openModalSendToCash
            , saveSale
            , infoSale
            , pdf
            , openPDF
            , fileName
            , titleReport

            , handleViewRecib
            , handleViewQuote
            , handleViewQuoteCompleted
            , sendCreateSale
            , handleSearchClient
            //  , handleSearchSaleType
            , handlerChangeClient
            , toCurrency
            , updateSale
        }
    }
})

<script setup>
import * as echarts from 'echarts';
import {computed, onMounted, ref} from "vue";
import ApiService from "@/core/services/ApiService";
import SelectFilter from "@/views/graficas/asset/FilterSelect.vue";
import moduleForm from "@/store/modules/cashiers/modules/new-cashiers";

const chartRef = ref(null);
let myChart = null;

const startDate = ref("");
const endDate = ref("");

const datos = ref([]);

const selectedUser = ref(null);

const userOptions = computed(() => moduleForm.selects.userOptions);

const formatDate = (date) => {
    if (!date) return '';
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

const subtractDays = () => {
    const newDate = formatDate(new Date());

    const fecha = new Date(newDate);
    fecha.setDate(fecha.getDate() - 30);

    return formatDate(fecha);
};

onMounted(async () => {
    resetFilterDateInitial();

    let url = `Graphics/AgentXTime?StartDate=${startDate.value}&EndDate=${endDate.value}&AgentId=08dbe1a8-d569-40e1-8a15-b1ac271c8fef`

    const res = await ApiService.query(url);

    datos.value = res.data.map(x => [x.fecha, x.total]);

    if (chartRef.value) {
        myChart = echarts.init(chartRef.value);
        myChart.setOption(option.value);
    }
});

const option = ref({
    title: {
        text: "Datos del agente",
        subtext: `${subtractDays()} - ${formatDate(new Date())}`,
        left: "center",
        textStyle: {
            fontSize: 15,
            fontWeight: 'bold'
        }
    },
    tooltip: {
        trigger: 'axis',
        textStyle: {
            fontSize: 10,
            fontWeight: 'normal',
        },
        formatter: (params) => {
            const dataPoint = params[0];
            return `
                <b>${dataPoint.axisValue}</b><br/>
                Total: ${dataPoint.data[1]}
            `;
        }
    },
    xAxis: {
        type: 'category',
        boundaryGap: false
    },
    yAxis: {
        type: 'value',
        boundaryGap: [0, '30%']
    },
    visualMap: {
        type: 'piecewise',
        show: false,
        dimension: 0,
        seriesIndex: 0,
        pieces: [
            {
                gt: 1,
                lt: 3,
                color: 'rgba(0, 0, 180, 0.4)'
            },
            {
                gt: 5,
                lt: 7,
                color: 'rgba(0, 0, 180, 0.4)'
            }
        ]
    },
    series: [
        {
            type: 'line',
            smooth: 0.6,
            symbol: 'none',
            lineStyle: {
                color: '#5470C6',
                width: 5
            },
            markLine: {
                symbol: ['none', 'none'],
                label: { show: false },
                data: [{ xAxis: 1 }, { xAxis: 3 }, { xAxis: 5 }, { xAxis: 7 }]
            },
            areaStyle: {},
            data: datos
        }
    ]
});

const resetFilterDateInitial = () => {
    endDate.value = formatDate(new Date());

    const startDateValue = new Date(endDate.value);
    startDateValue.setDate(startDateValue.getDate() - 30);

    startDate.value = formatDate(startDateValue);
};

const updateChart = async () => {
    let url = `Graphics/AgentXTime?StartDate=${startDate.value}&EndDate=${endDate.value}&AgentId=${selectedUser.value}`

    const res = await ApiService.query(url);

    const updatedData = res.data.map(x => [x.fecha, x.total]);

    option.value.series[0].data = updatedData;
    option.value.title.subtext = `${startDate.value} - ${endDate.value}`;

    if (myChart) {
        myChart.setOption(option.value);
    }
};

const searchUsers = (filterName) => {
    if (filterName.trim().length >= 3) {
        moduleForm.getComboUser(filterName);
    }
}
</script>

<template>

    <div class="conjunto">
        <div class="g-agentTime" ref="chartRef"></div>
        <div class="btnG">
            <button @click="updateChart" class="update-button">Actualizar</button>
            <div class="date-picker">
                <label>
                    Fecha Inicio:
                    <input class="styled-date-input" type="date" v-model="startDate"/>
                </label>
                <label>
                    Fecha Fin:
                    <input class="styled-date-input" type="date" v-model="endDate"/>
                </label>
                <label class="containerSelect">
                    Agente
                    <SelectFilter class="filter"
                                  :name="'productIds'"
                                  :options="userOptions"
                                  v-model="selectedUser"
                                  @search="searchUsers"
                                  :placeholder="'Filtro por nombre'"
                    />
                </label>
            </div>
        </div>
    </div>
</template>

<style scoped>
.conjunto {
    position: relative;
    width: 100%;
}

.styled-date-input {
    padding: 5px;
    font-size: 10px;
    border: 2px solid #0BB7AF;
    border-radius: 5px;
    outline: none;
    transition: all 0.3s ease;
}

label {
    display: flex;
    flex-direction: column;
}

.containerSelect {
    width: 300px;
    margin-top: 17px;
}

.g-agentTime {
    width: 100%;
    height: 400px;
}

.btnG {
    margin-left: 30px;
    position: absolute;
    bottom: -30px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.date-picker {
    display: flex;
    gap: 30px;
    justify-content: center;
    align-items: center;
}

.date-picker label {
    font-size: 12px;
}

.update-button {
    background-color: #007BFF;
    height: min-content;
    margin-top: 14px;
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
}

.update-button:hover {
    background-color: #0056b3;
}
</style>
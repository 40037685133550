<script setup>
import * as echarts from 'echarts';
import {onMounted, ref} from "vue";
import ApiService from "@/core/services/ApiService";

const chartRef = ref(null);
let myChart = null;

const startDate = ref(null);
const endDate = ref(null);

const local = ref(null);
const foraneo = ref(null);

const formatDate = (date) => {
    if (!date) return '';
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

const subtractDays = () => {
    const newDate = formatDate(new Date());

    const fecha = new Date(newDate);
    fecha.setDate(fecha.getDate() - 30);

    return formatDate(fecha);
};

onMounted(async () => {
    resetFilterDateInitial()

    let url = `Graphics/LocationType?StartDate=${startDate.value}&EndDate=${endDate.value}`

    const res = await ApiService.query(url);

    local.value = res.data.local
    foraneo.value = res.data.foraneo

    if (chartRef.value) {
        myChart = echarts.init(chartRef.value);
        myChart.setOption(option.value);
    }
});

const option = ref ({
    title:{
        text: "Ventas por localidad",
        subtext: `${subtractDays()} - ${formatDate(new Date())}`,
        left: "center",
        textStyle: {
            fontSize: 15,
            fontWeight: 'bold'
        }
    },
    grid: {
        top: 100
    },
    legend: {
        top: '13%',
        left: 'center'
    },
    series: [
        {
            name: 'Ventas',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
                show: false,
                position: 'center',
                formatter: function (params) {
                    // Aquí agregamos el signo de pesos al formatear la etiqueta del gráfico
                    return `${params.name}\nTotal: $${params.value}`;
                }
            },
            emphasis: {
                label: {
                    show: true,
                    fontSize: 15,
                    fontWeight: 'bold'
                }
            },
            labelLine: {
                show: false
            },
            data: [
                { value: local, name: 'Ventas local' },
                { value: foraneo, name: 'Ventas foraneo' }
            ]
        }
    ]
});

const resetFilterDateInitial = () => {
    endDate.value = formatDate(new Date());

    const startDateValue = new Date(endDate.value);
    startDateValue.setDate(startDateValue.getDate() - 30);

    // Asignar las fechas a las variables
    startDate.value = formatDate(startDateValue);
};

const updateChart = async () => {
    let url = `Graphics/LocationType?StartDate=${startDate.value}&EndDate=${endDate.value}`

    const res = await ApiService.query(url);

    const updatedData = [
        { value: res.data.local, name: 'Ventas local' },
        { value: res.data.foraneo, name: 'Ventas foraneo' }
    ];

    option.value.series[0].data = updatedData;
    option.value.title.subtext = `${startDate.value} - ${endDate.value}`;

    if (myChart) {
        myChart.setOption(option.value, true);
    }
}
</script>

<template>

    <div class="conjunto">
        <div class="g-typeLocation" ref="chartRef"></div>
        <div class="btnG">
            <button @click="updateChart" class="update-button">Actualizar</button>
            <div class="date-picker">
                <label>
                    Fecha Inicio:
                    <input class="styled-date-input" type="date" v-model="startDate" />
                </label>
                <label>
                    Fecha Fin:
                    <input class="styled-date-input" type="date" v-model="endDate" />
                </label>
            </div>
        </div>
    </div>
</template>

<style scoped>
.conjunto {
    position: relative;
}

.styled-date-input {
    padding: 5px;
    font-size: 10px;
    border: 2px solid #0BB7AF;
    border-radius: 5px;
    outline: none;
    transition: all 0.3s ease;
}

.g-typeLocation {
    width: 300px;
    height: 400px;
    //background-color: #0BB7AF;
}

.btnG{
    margin-left: -35px;
    position: absolute;
    bottom: 5px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.date-picker {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.date-picker label {
    font-size: 12px;
}

.update-button {
    background-color: #007BFF;
    height: min-content;
    margin-top: 14px;
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
}

.update-button:hover {
    background-color: #0056b3;
}
</style>
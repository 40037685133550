import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_el_form_item, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_select, {
          disabled: _ctx.disabled,
          multiple: _ctx.multiple,
          filterable: "",
          remote: "",
          "reserve-keyword": "",
          placeholder: _ctx.placeholder,
          "remote-method": (query) => _ctx.$emit('search', query),
          onChange: _ctx.handleChange,
          onClear: _ctx.handleClear,
          loading: _ctx.loading,
          modelValue: _ctx.internalValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalValue) = $event)),
          clearable: "",
          id: `select_filter-remote-${_ctx.key_control}`
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: option.id,
                label: option.name,
                value: option.id
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["disabled", "multiple", "placeholder", "remote-method", "onChange", "onClear", "loading", "modelValue", "id"])
      ]),
      _: 1
    })
  ]))
}
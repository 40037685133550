import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { RouteNames } from "./route-names";
import { canAccessRoute } from "@/core/helpers/canUserAccess";
import ApiService from "@/core/services/ApiService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/welcome",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: RouteNames.Dashboard,
        component: () => import("@/views/Dashboard.vue"),
      },
      /**INICIO - Modulos */
      // {
      //   path: "/sales",
      //   name: RouteNames.Sales,
      //   component: () => import("@/views/sales/Index.vue"),
      // },
      {
        path: "/welcome",
        name: RouteNames.Welcome,
        component: () => import("@/views/Welcome.vue"),
      },
      {
        path: "/sales",
        name: RouteNames.Sales,
        component: () => import("@/views/sales/IndexSales.vue"),
      },
      {
        path: "/OpenCash",
        name: RouteNames.OpenCash,
        component: () => import("@/views/cash/OpenCash.vue"),
      },
      {
        path: "/AdditionalFund",
        name: RouteNames.AdditionalFund,
        component: () => import("@/views/cash/AdditionalFund.vue"),
      },
      {
        path: "/RetireCash",
        name: RouteNames.RetireCash,
        component: () => import("@/views/cash/CashRegisterRetire.vue"),
      },
      {
        path: "/CloseCash",
        name: RouteNames.CloseCash,
        component: () => import("@/views/cash/CloseCash.vue"),
      },
      {
        path: "/CashMovements",
        name: RouteNames.CashMovements,
        component: () => import("@/views/cash/CashMovements.vue"),
      },
      {
        path: "/CashMovements/:id/CashMovementsDetails/",
        name: RouteNames.CashMovementsDetails,
        component: () => import("@/views/cash/CashMovementsDetails.vue"),
        props: true
      },
      {
        path: "/salesQuote",
        name: RouteNames.SalesQuote,
        component: () => import("@/views/sales/IndexSaleQuote.vue"),
      },
      {
        path: "/sales/detailSales",
        name: RouteNames.SalesDetail,
        component: () => import("@/views/sales/components/DetailSales.vue"),
      },
      {
        path: "/salesReturns",
        name:RouteNames.SalesReturns,
        component: () => import("@/views/returnsale/index.vue"),
      },
      {
        path: "/salesReturns/saleReturn/:action",
        name: "/salesReturns/saleReturn",
        component: () => import("@/views/returnsale/components/AddReturnSales.vue"),
        props: true,
      },
      {
        path: "/sales/sale/:action",
        name: "/sales/sale",
        component: () => import("@/views/sales/components/AddSales.vue"),
        props: true,
      },
      {
        path: "/deliveries",
        name: "/deliveries",
        component: () => import("@/views/deliverysale/index.vue"),
      },
      {
        path: "/deliveriesViewTable",
        name: "/deliveriesViewTable",
        component: () => import("@/views/deliverysale/indexViewTable.vue"),
      },
      {
        path: "/clients",
        name: RouteNames.Clients,
        component: () => import("@/views/clients/InitialView.vue"),
      },
      {
        path: "/billing/bill",
        name: RouteNames.Bill,
        component: () => import("@/views/billing/global/InitialView.vue"),
      },
      {
        path: "/billing/created",
        name: RouteNames.BillCreated,
        props: true,
        component: () => import("@/views/billing/created/InitialView.vue"),
      },
      {
        path: "/billing/sale",
        name: RouteNames.BillSale,
        component: () => import("@/views/billing/products/InitialView.vue"),
      },
      {
        path: "/physical-inventory/physical-count",
        name: RouteNames.PhysicalCount,
        component: () => import("@/views/physicalInventory/physical-count/InitialView.vue"),
      },
      {
        path: "/physical-inventory/movement-preview",
        name: RouteNames.MovementPreview,
        component: () => import("@/views/physicalInventory/movement-preview/InitialView.vue"),
      },
      {
        path: "/physical-inventory/physical-count/details",
        name: RouteNames.PhysicalCountDetailsModule,
        component: () => import("@/views/physicalInventory/physical-count/details/InitialView.vue"),
      },
       {
        path: "/reports",
        name: RouteNames.ReportsModule,
        component: () => import("@/views/reports/ReportView.vue"),
      },
      /* FIN - Modulos */
      /*INICIO - CATALOGOS*/
      /*{
        path: "/cashregister",
        name: "cashregister",
        component: () => import("@/views/cashregister/cashregister.vue"),
      },*/
      {
        path: "/billing/send",
        name: RouteNames.BillSend,
        component: () => import("@/views/billing/send/InitialView.vue"),
      },
      {
        path: "/reports",
        name: RouteNames.Report,
        component: () => import("@/views/reports/ReportView.vue"),
      },
      {
        path: "/graphics",
        name: RouteNames.Graphics,
        component: () => import("@/views/graficas/Graphics.vue"),
      },
      {
        path: "/supplierpayments",
        name: "supplierpayments",
        component: () => import("@/views/payment/payment.vue"),
      },
      {
        path: "/inputsOutputs",
        name: "inputsOutputs",
        component: () => import("@/views/inputsOutputs/AdminInputsOutputs.vue"),
      },
      {
        path: "/movementDetails",
        name: "movementDetails",
        component: () => import("@/views/inputsOutputs/MovementDetails.vue")
      },
      {
        path: "/users",
        name: RouteNames.Users,
        component: () => import("@/views/users/AdminUsersModule.vue"),
      },
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/views/Builder.vue"),
      },
      {
        path: "/movimientos",
        name: "movimientos",
        component: () => import("@/views/movimientos/Movimientos.vue"),
      },
      {
        path: "/settings/catalogs/census",
        name: "mod-census",
        component: () => import("@/catalogs/census/AdminCatCensus.vue"),
      },

      {
        path: "/settings/catalogs/unitTypes",
        name: "unit-type",
        component: () => import("@/catalogs/unit-type/AdminCatUnitType.vue"),
      },
      {
        path: "/settings/catalogs/articles",
        name: "modArticles",
        component: () => import("@/catalogs/articles/cat-admin-articles.vue"),
      },
      {
        path: "/settings/catalogs/:C0/edit",
        name: "editarticles",
        component: () => import("@/catalogs/articles/details/edit-article.vue"),
        props: true,
      },
      {
        path: "/settings/catalogs/families",
        name: RouteNames.Families,
        component: () => import("@/catalogs/families/Families.vue"),
      },
      {
        path: "/settings/catalogs/subFamilies",
        name: RouteNames.SubFamilies,
        component: () => import("@/catalogs/subFamilies/subFamilies.vue"),
      },
      {
        path: "/settings/catalogs/MeasurementUnits",
        name: "mod-measurementUnit",
        component: () =>
          import("@/catalogs/MeasurementUnits/MeasurementUnits.vue"),
      },
      {
        path: "/settings/catalogs/Lines",
        name: "mod-Lines",
        component: () => import("@/catalogs/Lines/Lines.vue"),
      },
      {
        path: "/settings/catalogs/colors",
        name: "mod-colors",
        component: () => import("@/catalogs/colors/cat-admin-colors.vue"),
      },
      {
        path: "/settings/catalogs/percentColors",
        name: "mod-percentColors",
        component: () =>
          import("@/catalogs/percentColors/cat-admin-percent-colors.vue"),
      },
      {
        path: "/settings/catalogs/taxes",
        name: "mod-taxes",
        component: () => import("@/catalogs/taxes/cat-admin-taxes.vue"),
      },
      {
        path: "/settings/catalogs/warehouses",
        name: "mod-warehouses",
        component: () =>
          import("@/catalogs/warehouses/cat-admin-warehouses.vue"),
      },
      {
        path: "/warehouseadmin",
        name: "warehouseadmin",
        component: () =>
          import("@/views/warehouseadmin/Index.vue"),
      },
      {
        path: "/settings/catalogs/currency",
        name: "mod-currency",
        component: () => import("@/catalogs/currency/cat-admin-currency.vue"),
      },
      {
        path: "/adminSalesPay",
        name: "adminSalesPay",
        component: () => import("@/views/checkoutBox/AdminSalesPay.vue"),
      },
      {
        path: "/adminSalesPay/box/:C0",
        name: "/adminSalesPay/box/:C0",
        component: () => import("@/views/checkoutBox/CheckoutBox.vue"),
        props: true,
      },
      {
        path: "/shopping/adminPurchaseOrders",
        name: RouteNames.PurchaseOrders,
        component: () =>
          import("@/views/shopping/purchase-orders/admin-purchase-order.vue"),
        props: true,
      },
      {
        path: "/shopping/adminPurchaseOrders/:purchaseOrderId/details",
        name: "/shopping/adminPurchaseOrders/:purchaseOrderId/details",
        component: () =>
          import("@/views/shopping/purchase-orders/purchase-order-details.vue"),
        props: true,
      },
      {
        path: "/shopping/receptions",
        name: RouteNames.Receptions,
        component: () =>
          import("@/views/shopping/receptions/receptions-main.vue"),
        props: true,
      },
      {
        path: "/shopping/adminReceptions/:C0/",
        name: "/shopping/adminReceptions/:C0",
        component: () =>
          import("@/views/shopping/receptions/reception-purchase-order.vue"),
        props: true,
      },
      {
        path: "/shopping/settlementPurchaseOrders/:C0",
        name: "/shopping/settlementPurchaseOrders/:C0",
        component: () =>
          import("@/views/shopping/purchase-orders/history-partial-payments.vue"),
        props: true,
      },
      {
        path: "/warehouse/adminInputs",
        name:RouteNames.Inputs,
        component: () =>
          import("@/views/warehouse/inputs/AdminInputs.vue"),
        props: true,
      },
      {
        path: "/warehouse/input/:inputId/details",
        name: "/warehouse/input/:inputId/details",
        component: () => import("@/views/warehouse/inputs/InputDetails.vue"),
        props: true
      },
      {
        path: "/warehouse/adminOutputs",
        name: RouteNames.Outputs,
        component: () =>
          import("@/views/warehouse/outputs/AdminOutputs.vue"),
        props: true,
      },
      {
        path: "/warehouse/output/:outputId/details",
        name: "/warehouse/output/:outputId/details",
        component: () => import("@/views/warehouse/outputs/OutputDetails.vue"),
        props: true
      },
      {
        path: "/warehouse/transfers",
        name: RouteNames.Transfers,
        component: () => import("@/views/warehouse/transfers/InitialView.vue")
      },
       {
        path: "/warehouse/transfers/details",
        name: RouteNames.TransfersDetails,
        component: () => import("@/views/warehouse/transfers/details/InitialView.vue")
      },
      {
        path: "/settings/catalogs/typesUseCfdi",
        name: RouteNames.TypeCfdi,
        component: () => import("@/catalogs/typeUseCfdi/UseCfdiCatalog.vue"),
      },
      {
        path: "/settings/catalogs/wayToPay",
        name: RouteNames.WayToPay,
        component: () => import("@/catalogs/wayToPay/WayToPayCatalog.vue"),
      },
      {
        path: "/settings/catalogs/paymentType",
        name: RouteNames.PaymentType,
        component: () =>
          import("@/catalogs/paymentTypeCatalog/PaymentTypeCatalog.vue"),
      },
      {
        path: "/settings/catalogs/country",
        name: RouteNames.Country,
        component: () => import("@/catalogs/country/CountryCatalog.vue"),
      },
      {
        path: "/settings/catalogs/state",
        name: RouteNames.State,
        component: () => import("@/catalogs/state/StateCatalog.vue"),
      },
      {
        path: "/settings/catalogs/population",
        name: RouteNames.Population,
        component: () => import("@/catalogs/population/PopulationCatalog.vue"),
      },
      {
        path: "/settings/catalogs/regime"
        , name: RouteNames.Regime
        , component: () => import('@/catalogs/regime/RegimeCatalog.vue')
      },
      {
        path: "/settings/catalogs/cashiers"
        , name: RouteNames.Cashiers
        , component: () => import('@/catalogs/cashiers/CashiersCatalog.vue')
      },
      {
        path: "/settings/catalogs/cash-registers"
        , name: RouteNames.CashRegisters
        , component: () => import('@/catalogs/cash-register/CashRegisterCatalog.vue')
      },
      {
        path: "/settings/catalogs/movement-type"
        , name: RouteNames.MovementType
        , component: () => import('@/catalogs/movementType/MovementTypeCatalog.vue')
      }
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: RouteNames.SignIn,
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: RouteNames.SignUp,
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  //validanmos el permisos de acceso.
  if (to.name && canAccessRoute(to.name as RouteNames)){
    ApiService.setHeader();//para evitar perder el bearer cuando se actualiza la ventana
    next()
  } 
  else if(!JwtService.getToken()){
    next({ name: RouteNames.SignIn })
  }
  else { 
    next({ name: RouteNames.Welcome })
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;

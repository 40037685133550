<script>

import DatePickerRange from "@/components/forms/DatePickerRange.vue";
import { Form } from 'vee-validate'
import {computed, defineComponent, onMounted, ref } from "vue";
import tableModule from "@/store/modules/werehouse/modules/inputs-table";
import * as Validations from "yup";
import debounce from 'lodash/debounce';
import SelectFilterRemote from "@/components/forms/SelectFilterRemote.vue";
import Select from "@/components/forms/Select.vue";
import ApiService from "@/core/services/ApiService";
import {getModule} from "vuex-module-decorators";
import NewProductEntryModule from "@/store/modules/productEntry/modules/new-product-entry";
import {getMessageError} from "@/core/helpers/messageFromPulmeros";
import SwalMessageService from "@/core/services/SwalMessageService";
import moduleForm from "@/store/modules/cashiers/modules/new-cashiers";
import InputText from "@/components/forms/InputText.vue";

export default defineComponent({
    components: {
        Select,
        SelectFilterRemote,
        DatePickerRange,
        Form
    },
    emits: ['emitData'],
    setup(_, { emit }) {
        // Saber que se debe de ver en el formulario 1
        const form1 = ref();
        const form2 = ref();
        const form3 = ref();
        const form4 = ref();
        const form5 = ref();
        const form6 = ref();

        const isViewForm1 = computed(() => { return !(form1.value === 0 || form1.value === 1) })
        const isViewForm4 = computed(() => { return form4.value === 2 })
        const isViewForm5 = computed(() => { return form5.value === 0 })
        const isViewForm6 = computed(() => { return !(form6.value === 3 || form6.value === 4 || form6.value === 5) })
        const isViewForm7 = computed(() => { return form6.value === 5 })

        const isViewBtn1 = computed(() => { return form1.value === 4 || form1.value === 5 })
        const isViewBtn2 = computed(() => { return form2.value === 0 || form2.value === 3 })
        const isViewBtn3 = computed(() => { return form3.value === 1 || form3.value === 2 })
        const isViewBtn6 = computed(() => { return form6.value === 2 || form6.value === 4 || form6.value === 5 })

        onMounted(() => {
            resetFilterDateInitial(formFilters)
            resetFilterDateInitial(formFilters2)
            resetFilterDateInitial(formFilters3)
            resetFilterDateInitial(formFilters4)
            resetFilterDateInitial(formFilters5)
            resetFilterDateAnterior(formFilters5)
            resetFilterDateInitial(formFilters6)
        });

        // Loading form1
        const loading1 = ref(false);
        const loading1_1 = ref(false);
        const loading2 = ref(false);
        const loading2_1 = ref(false);
        const loading3 = ref(false);
        const loading3_1 = ref(false);
        const loading4 = ref(false);
        const loading4_1 = ref(false);
        const loading5 = ref(false);
        const loading5_1 = ref(false);
        const loading6 = ref(false);
        const loading6_1 = ref(false);

        // Formularios para ventas
        const formFilters = ref();
        const formFilters2 = ref();
        const formFilters3 = ref();
        const formFilters4 = ref();
        const formFilters5 = ref();
        const formFilters6 = ref();

        // btn filters para ventas
        const btnFilters = ref();
        const btnFilters2 = ref();
        const btnFilters3 = ref();
        const btnFilters4 = ref();
        const btnFilters5 = ref();
        const btnFilters6 = ref();

        // btn para los excel
        const btnFilters1_1 = ref();
        const btnFilters2_1 = ref();
        const btnFilters3_1 = ref();
        const btnFilters4_1 = ref();
        const btnFilters5_1 = ref();
        const btnFilters6_1 = ref();
        const selectionAction = ref();

        // Los collapse para las ventas
        const idCollapse = ref('collapse-inputs-filters');
        const idCollapse2 = ref('collapse-inputs-filters2');
        const idCollapse3 = ref('collapse-inputs-filters3');
        const idCollapse4 = ref('collapse-inputs-filters4');
        const idCollapse5 = ref('collapse-inputs-filters5');
        const idCollapse6 = ref('collapse-inputs-filters6');

        const messageService = new SwalMessageService();
        const moduleProduct = getModule(NewProductEntryModule);

        let suppliers = ref("");
        let cashiers = ref("");
        let family = ref("");
        let subfamily = ref(""); 
        let linea = ref("");
        let unidad = ref("");
        const comboUnidad = computed(() => { return unidad.value });
        const comboSupplier = computed(() => { return suppliers.value });
        const comboCashier = computed(() => { return cashiers.value });
        const comboFamily = computed(() => { return family.value });
        const comboSubFamily = computed(() => { return subfamily.value });
        const comboLine = computed(() => { return linea.value });
        const customerOptions = computed(() => tableModule.selects.customerOptions)
        const userOptions = computed(() => moduleForm.selects.userOptions);
        const productsCombo = computed(() => moduleProduct.productOptions);
        const productsLoading = computed(() => moduleProduct.loadings.productSelect);

        // tipos de reportes
        const typeTax = [
            { id: 0, name: "Todos"},
            { id: 1, name: "Gravado"},
            { id: 2, name: "Excento"}
        ]

        const typeTransaction = [
            { id: 0, name: "Todos"},
            { id: 1, name: "Remisiones"},
            { id: 2, name: "Facturas"}
        ]

        const typeLocation = [
            { id: 0, name: "Todos"},
            { id: 1, name: "Local"},
            { id: 2, name: "Foraneo"},
        ]

        const typeReport = [
            { id: 0, name: "Mes"},
            { id: 1, name: "Dia"},
            { id: 2, name: "Folio"},
            { id: 3, name: "Detallado"},
            { id: 4, name: "FormaPago"},
            { id: 5, name: "Pagos"}
        ]

        const typeReport2 = [
            { id: 0, name: "Resumen"},
            { id: 2, name: "Detalle"},
            { id: 3, name: "Existencia"}
        ]

        const typeReport3 = [
            { id: 0, name: "Resumen"},
            { id: 1, name: "Normal"},
            { id: 2, name: "Saldo pendiente"},
            { id: 3, name: "Saldo pendiente detallado"}
        ]

        const typeReport4 = [
            { id: 0, name: "Familia"},
            { id: 1, name: "Proveedor"},
            { id: 2, name: "Agente"},
        ]

        const typeReport5 = [
            { id: 0, name: "Cliente"},
            { id: 1, name: "Agente"},
        ]

        const typeReport6 = [
            { id: 0, name: "Periodo"},
            { id: 2, name: "Linea"},
            { id: 3, name: "Saldo"},
            { id: 4, name: "Saldo detallado"},
            { id: 5, name: "Tipo Locación"},
        ]

        // Validaciones
        const validationSchemaFilters = Validations.object().shape({
            startDate: Validations.date().required("La fecha es obligatoria")
            , endDate: Validations.date().required("La fecha es obligatoria")
            , catTax: Validations.string().default('').required("Selecciona una opción")
            , docTransaction: Validations.string().default('').required("Selecciona una opción")
            , reportSales: Validations.string().default('').required("Selecciona una opción")
            , customerIds: Validations.string().default('').optional()
            , agentIds: Validations.string().default('').optional()
            , cashierIds: Validations.string().default('').optional()
        })

        const validationSchemaFilters2 = Validations.object().shape({
            startDate: Validations.date().required("La fecha es obligatoria")
            , endDate: Validations.date().required("La fecha es obligatoria")
            , docTransaction: Validations.string().default('').required("Selecciona una opción")
            , reportSales: Validations.string().default('').required("Selecciona una opción")
            , customerIds: Validations.string().default('').optional()
            , supplierIds: Validations.string().default('').optional()
            , agentIds: Validations.string().default('').optional()
            , productIds: Validations.string().default('').optional()
            , familyIds: Validations.string().default('').optional()
                    .when('classIds', {
                        is: (value) => value !== '',
                        then: (schema) => schema.required("La familia es obligatorio si se ingresa una clase")
                    })
                    .when('lineIds', {
                        is: (value) => value !== '',
                        then: (schema) => schema.required("La familia es obligatorio si se ingresa una linea")
                    })
            , classIds: Validations.string().default('').optional()
                    .when('lineIds', {
                        is: (value) => value !== '',
                        then: (schema) => schema.required("La clase es obligatorio si se ingresa una linea")
                    })
            , lineIds: Validations.string().default('').optional()
        })

        const validationSchemaFilters3 = Validations.object().shape({
            startDate: Validations.date().required("La fecha es obligatoria")
            , endDate: Validations.date().required("La fecha es obligatoria")
            , reportSales: Validations.string().default('').required("Selecciona una opción")
            , customerIds: Validations.string().default('').optional()
            , agentIds: Validations.string().default('').optional()
        })

        const validationSchemaFilters4 = Validations.object().shape({
            startDate: Validations.date().required("La fecha es obligatoria")
            , endDate: Validations.date().required("La fecha es obligatoria")
            , reportSales: Validations.string().default('').required("Selecciona una opción")
            , agentIds: Validations.string().default('').optional()
        })

        const validationSchemaFilters5 = Validations.object().shape({
            startDate: Validations.date().required("La fecha es obligatoria")
            , endDate: Validations.date().required("La fecha es obligatoria")
            , startAnterior: Validations.date().required("La fecha es obligatoria")
            , endAnterior: Validations.date().required("La fecha es obligatoria")
            , reportSales: Validations.string().default('').required("Selecciona una opción")
            , agentIds: Validations.string().default('').optional()
            , customerIds: Validations.string().default('').optional()
            , familyIds: Validations.string().default('').optional()
                .when('classIds', {
                    is: (value) => value !== '',
                    then: (schema) => schema.required("La familia es obligatorio si se ingresa una clase")
                })
                .when('lineIds', {
                    is: (value) => value !== '',
                    then: (schema) => schema.required("La familia es obligatorio si se ingresa una linea")
                })
            , classIds: Validations.string().default('').optional()
                .when('lineIds', {
                    is: (value) => value !== '',
                    then: (schema) => schema.required("La clase es obligatorio si se ingresa una linea")
                })
            , lineIds: Validations.string().default('').optional()
        })

        const validationSchemaFilters6 = Validations.object().shape({
            startDate: Validations.date().required("La fecha es obligatoria")
            , endDate: Validations.date().required("La fecha es obligatoria")
            , reportSales: Validations.string().default('').required("Selecciona una opción")
            , agentIds: Validations.string().default('').optional()
            , familyIds: Validations.string().default('').optional()
                .when('classIds', {
                    is: (value) => value !== '',
                    then: (schema) => schema.required("La familia es obligatorio si se ingresa una clase")
                })
                .when('lineIds', {
                    is: (value) => value !== '',
                    then: (schema) => schema.required("La familia es obligatorio si se ingresa una linea")
                })
            , classIds: Validations.string().default('').optional()
                .when('lineIds', {
                    is: (value) => value !== '',
                    then: (schema) => schema.required("La clase es obligatorio si se ingresa una linea")
                })
            , lineIds: Validations.string().default('').optional()
        })

        // Funciones para generar los reportes sobre ventas
        const searchSalesPeriod = async (filters) => {
            if(selectionAction.value === 'excel'){
                await ExcelForm1(filters.startDate, filters.endDate, filters.catTax, filters.docTransaction, filters.reportSales, filters.cashierIds, filters.agentIds, filters.customerIds);
            } else {
                await PDFForm1(filters.startDate, filters.endDate, filters.catTax, filters.docTransaction, filters.reportSales, filters.cashierIds, filters.agentIds, filters.customerIds);
            }
        }

        const searchSalesArticle = async (filters) => {
            if(selectionAction.value === 'excel'){
                await ExcelForm2(filters.startDate, filters.endDate, filters.docTransaction, filters.reportSales, filters.customerIds, filters.supplierIds, filters.agentIds, filters.productIds, filters.familyIds, filters.classIds, filters.lineIds, filters.unidadIds);
            } else {
                await PDFForm2(filters.startDate, filters.endDate, filters.docTransaction, filters.reportSales, filters.customerIds, filters.supplierIds, filters.agentIds, filters.productIds, filters.familyIds, filters.classIds, filters.lineIds, filters.unidadIds);
            }
        }

        const searchSalesClient = async (filters) => {
            if(selectionAction.value === 'excel'){
                await ExcelForm3(filters.startDate, filters.endDate, filters.reportSales, filters.customerIds, filters.agentIds);
            } else {
                await PDFForm3(filters.startDate, filters.endDate, filters.reportSales, filters.customerIds, filters.agentIds);
            }
        }

        const searchSalesX = async (filters) => {
            if(selectionAction.value === 'excel'){
                await ExcelForm4(filters.startDate, filters.endDate, filters.agentIds, filters.reportSales);
            } else {
                await PDFForm4(filters.startDate, filters.endDate, filters.agentIds, filters.reportSales);
            }
        }

        const searchSalesComparativo = async (filters) => {
            if(selectionAction.value === 'excel'){
                await ExcelForm5(filters.startAnterior, filters.endAnterior, filters.startDate, filters.endDate, filters.customerIds, filters.agentIds, filters.lineIds, filters.reportSales);
            } else {
                await PDFForm5(filters.startAnterior, filters.endAnterior, filters.startDate, filters.endDate, filters.customerIds, filters.agentIds, filters.lineIds, filters.reportSales);
            }
        }

        const searchSalesAgentX = async (filters) => {
            if(selectionAction.value === 'excel'){
                await ExcelForm6(filters.startDate, filters.endDate, filters.reportSales, filters.agentIds, filters.lineIds, filters.typeLocation);
            } else {
                await PDFForm6(filters.startDate, filters.endDate, filters.reportSales, filters.agentIds, filters.lineIds, filters.typeLocation);
            }
        }

        // Excel y pdf generators
        const ExcelForm1 = async (inicio, final, catImpuestos, docTransation, typeReport, caja, agente, cliente) => {
            let urls = `Reports/ReportSalesPeriodExcel?Inicio=${inicio}&Final=${final}&CatImpuestos=${catImpuestos}&DocTransaction=${docTransation}&ReportSales=${typeReport}` +
                (caja ? `&CajaId=${caja}` : '') +
                (agente ? `&AgenteId=${agente}` : '') +
                (cliente ? `&ClienteId=${cliente}` : '');

            await procesoExcel(loading1_1, urls, 'Reporte_ventas.xlsx', resetFilterForm1);
        }

        const ExcelForm2 = async (inicio, final, docTransation, typeReport, cliente, proveedor, agente, producto, familia, clase, linea, unidad) => {
            let urls = `Reports/ReportSalesArticleExcel?Inicio=${inicio}&Final=${final}&DocTransaction=${docTransation}&ReportSales=${typeReport}` +
                (cliente ? `&ClienteId=${cliente}` : '') +
                (proveedor ? `&ProveedorId=${proveedor}` : '') +
                (producto ? `&ProductoId=${producto}` : '') +
                (agente ? `&AgenteId=${agente}` : '') +
                (familia ? `&FamiliaId=${familia}` : '') +
                (clase ? `&ClaseId=${clase}` : '') +
                (unidad ? `&UnidadId=${unidad}` : '') +
                (linea ? `&LineaId=${linea}` : '');

            await procesoExcel(loading2_1, urls, 'Reporte_ventas.xlsx', resetFilterForm2);
        }

        const ExcelForm3 = async (inicio, final, typeReport, cliente, agente) => {
            let urls = `Reports/ReportSalesClientExcel?Inicio=${inicio}&Final=${final}&ReportSales=${typeReport}` +
                (cliente ? `&ClienteId=${cliente}` : '') +
                (agente ? `&AgenteId=${agente}` : '');

            await procesoExcel(loading3_1, urls, 'Reporte_ventas.xlsx', resetFilterForm3);
        }

        const ExcelForm4 = async (inicio, final, agente, typeReport) => {
            let urls = "";

            if(typeReport !== 2)
                urls = `Reports/ReportSalesXExcel?Inicio=${inicio}&Fin=${final}&ReportSales=${typeReport}`;
            else
                urls = urls = `Reports/ReportSalesAgentExcel?Inicio=${inicio}&Fin=${final}&ReportAgent=0` +
                    (agente ? `&AgentId=${agente}` : '');

            await procesoExcel(loading4_1, urls, 'Reporte_ventas.xlsx', resetFilterForm4);
        }

        const ExcelForm5 = async (inicio, final, inicioNuevo, finalNuevo, cliente, agente, linea, typeReport) => {
            let urls = "";

            if(typeReport === 0){
                urls = `Reports/ReportSalesClientArticleExcel?InicioAnterior=${inicio}&FinalAnterior=${final}&Inicio=${inicioNuevo}&Final=${finalNuevo}` +
                    (cliente ? `&ClienteId=${cliente}` : '') +
                    (agente ? `&AgenteId=${agente}` : '') +
                    (linea ? `&LineaId=${linea}` : '');
            } else {
                urls = `Reports/ReportSalesAgentXExcel?InicioAnterior=${inicio}&FinalAnterior=${final}&Inicio=${inicioNuevo}&Final=${finalNuevo}&ReportSales=${typeReport}` +
                    (agente ? `&AgentId=${agente}` : '') +
                    (linea ? `&LineId=${linea}` : '');
            }

            await procesoExcel(loading5_1, urls, 'Reporte_ventas.xlsx', resetFilterForm5);
        }

        const ExcelForm6 = async (inicio, final, typeReport, agente, linea, location) => {
            let urls = "";

            if(typeReport === 2){
                urls = `Reports/ReportSalesAgentXExcel?Inicio=${inicio}&Final=${final}&ReportSales=${typeReport}` +
                    (agente ? `&AgentId=${agente}` : '') +
                    (linea ? `&LineId=${linea}` : '');
            } else if(typeReport === 4){
                urls = `Reports/ReportSalesAgentExcel?Inicio=${inicio}&Fin=${final}&ReportAgent=${typeReport - 2}` +
                    (agente ? `&AgentId=${agente}` : '');
            } else if(typeReport === 5) {
                urls = `Reports/ReportSalesLocationExcel?Inicio=${inicio}&Fin=${final}&LocationType=${location}` +
                    (agente ? `&AgentId=${agente}` : '');
            }

            await procesoExcel(loading6_1, urls, 'Reporte_ventas.xlsx', resetFilterForm6);
        }

        const PDFForm1 = async (inicio, final, catImpuestos, docTransation, typeReports, caja, agente, cliente) => {
            let url = `Reports/ReportSalesPeriod?Inicio=${inicio}&Final=${final}&CatImpuestos=${catImpuestos}&DocTransaction=${docTransation}&ReportSales=${typeReports}` +
                (caja ? `&CajaId=${caja}` : '') +
                (agente ? `&AgenteId=${agente}` : '') +
                (cliente ? `&ClienteId=${cliente}` : '');

            let nombre = "Ventas por " + typeReport.find(x => x.id === typeReports).name;
            await procesoPdf(loading1, url, nombre, resetFilterForm1);
        }

        const PDFForm2 = async (inicio, final, docTransation, typeReport, cliente, proveedor, agente, producto, familia, clase, linea, unidad) => {
            let url = `Reports/ReportSalesArticle?Inicio=${inicio}&Final=${final}&DocTransaction=${docTransation}&ReportSales=${typeReport}` +
                (cliente ? `&ClienteId=${cliente}` : '') +
                (proveedor ? `&ProveedorId=${proveedor}` : '') +
                (producto ? `&ProductoId=${producto}` : '') +
                (agente ? `&AgenteId=${agente}` : '') +
                (familia ? `&FamiliaId=${familia}` : '') +
                (clase ? `&ClaseId=${clase}` : '') +
                (unidad ? `&UnidadId=${unidad}` : '') +
                (linea ? `&LineaId=${linea}` : '');

            let nombre = "Ventas por articulo";
            await procesoPdf(loading2, url, nombre, resetFilterForm2);
        }

        const PDFForm3 = async (inicio, final, typeReport, cliente, agente) => {
            let url = `Reports/ReportSalesClient?Inicio=${inicio}&Final=${final}&ReportSales=${typeReport}` +
                (cliente ? `&ClienteId=${cliente}` : '') +
                (agente ? `&AgenteId=${agente}` : '');

            let nombre = "Ventas por cliente";
            await procesoPdf(loading3, url, nombre, resetFilterForm3);
        }

        const PDFForm4 = async (inicio, final, agente, typeReport) => {
            let url = "";
            let nombre = "";

            if(typeReport !== 2) {
                nombre = typeReport === 0 ? "Ventas por familia" : "Ventas por proveedor";
                url = `Reports/ReportSalesX?Inicio=${inicio}&Fin=${final}&ReportSales=${typeReport}`;
            } else {
                nombre = "Ventas por agente";
                url = `Reports/ReportSalesAgent?Inicio=${inicio}&Fin=${final}&ReportAgent=0` +
                    (agente ? `&AgentId=${agente}` : '');
            }

            await procesoPdf(loading4, url, nombre, resetFilterForm4);
        }

        const PDFForm5 = async (inicio, final, inicioNuevo, finalNuevo, cliente, agente, linea, typeReport) => {
            let url = "";
            let nombre = "";

            if(typeReport === 0){
                nombre = "Reporte de ventas por comparación: Cliente";
                url = `Reports/ReportSalesClientArticle?InicioAnterior=${inicio}&FinalAnterior=${final}&Inicio=${inicioNuevo}&Final=${finalNuevo}` +
                    (cliente ? `&ClienteId=${cliente}` : '') +
                    (agente ? `&AgenteId=${agente}` : '') +
                    (linea ? `&LineaId=${linea}` : '');
            } else {
                nombre = "Reporte de ventas por comparación: Agente";
                url = `Reports/ReportSalesAgentX?InicioAnterior=${inicio}&FinalAnterior=${final}&Inicio=${inicioNuevo}&Final=${finalNuevo}&ReportSales=${typeReport}` +
                    (agente ? `&AgentId=${agente}` : '') +
                    (linea ? `&LineId=${linea}` : '');
            }

            await procesoPdf(loading5, url, nombre, resetFilterForm5);
        }

        const PDFForm6 = async (inicio, final, typeReport, agente, linea, location) => {
            let url = "";
            let nombre = "";

            if(typeReport === 0 || typeReport === 2){
                nombre = typeReport === 0 ? "Reporte de ventas de agentes por periodo" : "Reporte de ventas de agentes por linea"
                url = `Reports/ReportSalesAgentX?Inicio=${inicio}&Final=${final}&ReportSales=${typeReport}` +
                    (agente ? `&AgentId=${agente}` : '') +
                    (linea ? `&LineId=${linea}` : '');
            } else if(typeReport === 3 || typeReport === 4){
                nombre = "Reporte de saldos por agente"
                url = `Reports/ReportSalesAgent?Inicio=${inicio}&Fin=${final}&ReportAgent=${typeReport - 2}` +
                    (agente ? `&AgentId=${agente}` : '');
            } else if(typeReport === 5) {
                nombre = "Reporte de ventas por locacion"
                url = `Reports/ReportSalesLocation?Inicio=${inicio}&Fin=${final}&LocationType=${location}` +
                    (agente ? `&AgentId=${agente}` : '');
            }

            await procesoPdf(loading6, url, nombre, resetFilterForm6);
        }

        const procesoExcel = async (loading, urls, name, reset) => {
            loading.value = true;

            const res = await ApiService.dowloadFileGet(urls);
            const url = window.URL.createObjectURL(res.data);

            const a = document.createElement('a');
            a.href = url;
            a.download = name;
            document.body.appendChild(a);
            a.click();
            a.remove();

            loading.value = false;
            reset();
        }

        const procesoPdf = async (loading, url, name, reset) => {
            loading.value = true;

            const res = await ApiService.query(url);
            emit("emitData", res.data, name)

            loading.value = false;
            reset();
        }

        // Reseteos de formularios
        const resetFilterForm1 = () => {
            formFilters.value.resetForm();
            resetFilterDateInitial(formFilters)
            if (formFilters.value) {
                formFilters.value.setFieldValue('catTax', 0);
                formFilters.value.setFieldValue('docTransaction', 0);
                form1.value = null;
            }
        }

        const resetFilterForm2 = () => {
            formFilters2.value.resetForm();
            resetFilterDateInitial(formFilters2)
            formFilters2.value.setFieldValue('docTransaction', 0);
            form2.value = null;
        }

        const resetFilterForm3 = () => {
            formFilters3.value.resetForm();
            resetFilterDateInitial(formFilters3)
            form3.value = null;
        }

        const resetFilterForm4 = () => {
            formFilters4.value.resetForm();
            resetFilterDateInitial(formFilters4)
            form4.value = null;
        }

        const resetFilterForm5 = () => {
            formFilters5.value.resetForm();
            resetFilterDateInitial(formFilters5)
            resetFilterDateAnterior(formFilters5)
            form5.value = null;
        }

        const resetFilterForm6 = () => {
            formFilters6.value.resetForm();
            resetFilterDateInitial(formFilters6)
            formFilters6.value.setFieldValue('typeLocation' +       '', 0);
            form6.value = null;

        }

        const resetxFamily = () => {
            if (formFilters2.value) {
                formFilters2.value.setFieldValue('classIds', '');
                formFilters2.value.setFieldValue('lineIds', '');
            }
        }

        const resetxClass = () => {
            if (formFilters2.value) {
                formFilters2.value.setFieldValue('lineIds', '');
            }
        }

        const resetxFamily2 = () => {
            if (formFilters5.value) {
                formFilters5.value.setFieldValue('classIds', '');
                formFilters5.value.setFieldValue('lineIds', '');
            }
        }

        const resetxClass2 = () => {
            if (formFilters5.value) {
                formFilters5.value.setFieldValue('lineIds', '');
            }
        }

        const resetxFamily3 = () => {
            if (formFilters6.value) {
                formFilters6.value.setFieldValue('classIds', '');
                formFilters6.value.setFieldValue('lineIds', '');
            }
        }

        const resetxClass3 = () => {
            if (formFilters6.value) {
                formFilters6.value.setFieldValue('lineIds', '');
            }
        }

        const resetFilterDateInitial = (formFilter) => {
            const endDate = new Date();
            const startDate = new Date(endDate);
            startDate.setDate(endDate.getDate() - 120);

            if (formFilter.value) {
                formFilter.value.setFieldValue('startDate', startDate.toISOString());
                formFilter.value.setFieldValue('endDate', endDate.toISOString());
            }
        };

        const resetFilterDateAnterior = (formFilter) => {
            const endDate = new Date();
            const startDate = new Date(endDate);

            endDate.setDate(endDate.getDate() - 120)
            startDate.setDate(endDate.getDate() - 120); // -30

            if (formFilter.value) {
                formFilter.value.setFieldValue('startAnterior', startDate.toISOString());
                formFilter.value.setFieldValue('endAnterior', endDate.toISOString());
            }
        };

        //Combo options
        const getComboOptions = async (resource, container, parentId = "", filter = "") => {
            let parentParam = parentId.trim() !== "" ? `${parentId}/` : "";
            let url = `${resource}/${parentParam}combo?Criteria=${filter}`;
            ApiService.query(url).then(res => {
                container.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const getCombFamily = (resource, container, parentId = "", filter = "") => {
            let parentParam = parentId.trim() !== "" ? `${parentId}/` : "";
            let url = `${resource}/${parentParam}combos?criteria=${filter}`;
            ApiService.query(url).then(res => {
                container.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const getComboLine = (resource, container, parentId = "", subParentId = "", filter = "") => {
            let url = `${resource}/${parentId}/${subParentId}/combo?criteria=${filter}`;
            ApiService.query(url).then(res => {
                container.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const getComboUnidad = async (resource, container, parentId = "", filter = "") => {
            let parentParam = parentId.trim() !== "" ? `${parentId}/` : "";
            let url = `${resource}/${parentParam}combo?criteria=${filter}`;
            ApiService.query(url).then(res => {
                container.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        //Buscadores
        const handleSearchProducts = (name) => {
            if(name.trim().length >= 3){
                moduleProduct.SEARCH_PRODUCTS(name);
            }
        }

        const searchSuppliers = async (filter) => {
            if (filter.trim().length >= 3) {
                await getComboOptions("suppliers", suppliers, "", filter);
            }
        }

        const searchUnidad = async (filter) => {
            await getComboUnidad("MeasurementUnits", unidad, "", filter);
        }

        const searchCasher = async (filter) => {
            if (filter.trim().length >= 3) {
                await getComboOptions("Cashiers", cashiers, "", filter);
            }
        }

        const searchFamily = async (filter) => {
            if (filter.trim().length >= 3) {
                await getCombFamily("Families", family, "", filter);
            }
        }

        const searchSubFamily = async (filter) => {
            if(formFilters2.value.values.familyIds != null){
                await getCombFamily("SubFamilies", subfamily, formFilters2.value.values.familyIds, filter);
            }
        }

        const searchLinea = async (filter) => {
            if(formFilters2.value.values.familyIds != null && formFilters2.value.values.classIds != null){
                await getComboLine("Lines", linea, formFilters2.value.values.familyIds, formFilters2.value.values.classIds, filter);
            }
        }

        const searchSubFamily2 = async (filter) => {
            if(formFilters5.value.values.familyIds != null){
                await getCombFamily("SubFamilies", subfamily, formFilters5.value.values.familyIds, filter);
            }
        }

        const searchLinea2 = async (filter) => {
            if(formFilters5.value.values.familyIds != null && formFilters5.value.values.classIds != null){
                await getComboLine("Lines", linea, formFilters5.value.values.familyIds, formFilters5.value.values.classIds, filter);
            }
        }

        const searchSubFamily3 = async (filter) => {
            if(formFilters6.value.values.familyIds != null){
                await getCombFamily("SubFamilies", subfamily, formFilters6.value.values.familyIds, filter);
            }
        }

        const searchLinea3 = async (filter) => {
            if(formFilters6.value.values.familyIds != null && formFilters6.value.values.classIds != null){
                await getComboLine("Lines", linea, formFilters6.value.values.familyIds, formFilters6.value.values.classIds, filter);
            }
        }

        const searchUsers = (filterName) => {
            if (filterName.trim().length >= 3) {
                moduleForm.getComboUser(filterName);
            }
        }

        const searchCustomers = debounce((filterText) => {
            if (filterText.trim().length >= 3) {
                tableModule.getComboCustomer(filterText);
            }
        }, 550, { leading: false, trailing: true });

        return {
            idCollapse,
            idCollapse2,
            idCollapse3,
            idCollapse4,
            idCollapse5,
            idCollapse6,
            searchSalesPeriod,
            searchSalesArticle,
            searchSalesClient,
            searchSalesComparativo,
            searchSalesX,
            searchSalesAgentX,
            formFilters,
            formFilters2,
            formFilters3,
            formFilters4,
            formFilters5,
            formFilters6,
            btnFilters,
            btnFilters2,
            btnFilters3,
            btnFilters4,
            btnFilters5,
            btnFilters6,
            btnFilters1_1,
            btnFilters2_1,
            btnFilters3_1,
            btnFilters4_1,
            btnFilters5_1,
            btnFilters6_1,
            selectionAction,
            validationSchemaFilters,
            validationSchemaFilters2,
            validationSchemaFilters3,
            validationSchemaFilters4,
            validationSchemaFilters5,
            validationSchemaFilters6,
            customerOptions,
            typeTransaction,
            typeTax,
            typeLocation,
            typeReport,
            typeReport2,
            typeReport3,
            typeReport4,
            typeReport5,
            typeReport6,
            productsCombo,
            productsLoading,
            userOptions,
            comboSupplier,
            comboCashier,
            comboFamily,
            comboSubFamily,
            comboLine,
            comboUnidad,
            handleSearchProducts,
            searchCustomers,
            searchSuppliers,
            searchUsers,
            searchCasher,
            searchUnidad,
            searchFamily,
            searchSubFamily,
            searchLinea,
            searchSubFamily2,
            searchLinea2,
            searchSubFamily3,
            searchLinea3,
            form1,
            isViewForm1,
            isViewBtn1,
            form2,
            isViewBtn2,
            form3,
            isViewBtn3,
            form4,
            isViewForm4,
            form5,
            isViewForm5,
            form6,
            isViewBtn6,
            isViewForm6,
            isViewForm7,
            resetxFamily,
            resetxClass,
            resetxFamily2,
            resetxClass2,
            resetxFamily3,
            resetxClass3,
            loading1,
            loading1_1,
            loading2,
            loading2_1,
            loading3,
            loading3_1,
            loading4,
            loading4_1,
            loading5,
            loading5_1,
            loading6,
            loading6_1,
        };
    },
    watch: {
        'formFilters2.values.familyIds': function() {
            this.resetxFamily();
        },
        'formFilters2.values.classIds': function() {
            this.resetxClass();
        },
        'formFilters5.values.familyIds': function() {
            this.resetxFamily2();
        },
        'formFilters5.values.classIds': function() {
            this.resetxClass2();
        },
        'formFilters6.values.familyIds': function() {
            this.resetxFamily3();
        },
        'formFilters6.values.classIds': function() {
            this.resetxClass3();
        },
    }
})
</script>

<template>
    <div>
        <div class="card mb-3 mb-xl-3">
            <div
                class="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                :data-bs-target="`#${idCollapse}`"
                aria-expanded="true"
                :aria-controls="idCollapse"
            >
                <div class="card-title m-0">
                    <h3 class="fw-bolder ml-2 color-text">{{ $t('LABELS.VENTAS_PERIODO') }}</h3>
                </div>
            </div>
            <div class="collapse hide m-10" :id="idCollapse">
                <Form as="el-form" @submit="searchSalesPeriod" :validation-schema="validationSchemaFilters"  ref="formFilters">
                    <div class="row g-9 mb-8">
                        <div :class="isViewForm1 ? 'col-md-3' : 'col-md-4'" class="col-xs-12 fv-row">
                            <label>{{ $t('LABELS.REPORT') }}</label>
                            <Select v-model="form1" :name="'reportSales'" :options="typeReport">
                            </Select>
                        </div>
                        <div :class="isViewForm1 ? 'col-md-3' : 'col-md-4'" class="col-xs-12 fv-row">
                            <label>{{ $t('LABELS.DATE') }}</label>
                            <DatePickerRange></DatePickerRange>
                        </div>
                        <div :class="isViewForm1 ? 'col-md-3' : 'col-md-4'" class="col-xs-12  fv-row">
                            <label>{{ $t('LABELS.TAX') }}</label>
                            <Select :name="'catTax'" :options="typeTax" :defaultValue="true">
                            </Select>
                        </div>
                        <div class="col-md-3 col-xs-12  fv-row" v-show="isViewForm1">
                            <label>{{ $t('LABELS.TRANSACTION') }}</label>
                            <Select :name="'docTransaction'" :options="typeTransaction" :defaultValue="true">
                            </Select>
                        </div>
                    </div>
                    <div class="row g-9 mb-8">
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_CUSTOMER') }}</label>
                            <SelectFilterRemote :name="'customerIds'" :options="customerOptions"
                                                @search="searchCustomers"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_AGENT') }}</label>
                            <SelectFilterRemote :name="'agentIds'" :options="userOptions"
                                                @search="searchUsers"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_CASHIER') }}</label>
                            <SelectFilterRemote :name="'cashierIds'" :options="comboCashier"
                                                @search="searchCasher"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <button v-show="isViewBtn1" type="submit" class="btn btn-success me-3" ref="btnFilters1_1" @click="selectionAction = 'excel'">
                            <span v-if="loading1_1" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            {{ $t('BUTTONS.GENERATE_EXCEL') }}
                        </button>
                        <button type="submit" class="btn btn-success" ref="btnFilters" @click="selectionAction = 'report'">
                            <span v-if="loading1" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            {{ $t('BUTTONS.GENERATE_REPORT') }}
                        </button>
                    </div>
                </Form>
            </div>
        </div>
        <div class="card mb-3 mb-xl-3">
            <div
                class="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                :data-bs-target="`#${idCollapse2}`"
                aria-expanded="true"
                :aria-controls="idCollapse2"
            >
                <div class="card-title m-0">
                    <h3 class="fw-bolder ml-2 color-text">{{ $t('LABELS.VENTAS_ARTICULO') }}</h3>
                </div>
            </div>
            <div class="collapse hide m-10" :id="idCollapse2">
                <Form as="el-form" @submit="searchSalesArticle" :validation-schema="validationSchemaFilters2"  ref="formFilters2">
                    <div class="row g-9 mb-8">
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.REPORT') }}</label>
                            <Select  v-model="form2" :name="'reportSales'" :options="typeReport2">

                            </Select>
                        </div>
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.DATE') }}</label>
                            <DatePickerRange></DatePickerRange>
                        </div>
                        <div class="col-md-4 col-xs-12  fv-row">
                            <label>{{ $t('LABELS.TRANSACTION') }}</label>
                            <Select :name="'docTransaction'" :options="typeTransaction" :defaultValue="true">

                            </Select>
                        </div>
                    </div>
                    <div class="row g-9 mb-8">
                        <div class="col-md-3 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_CUSTOMER') }}</label>
                            <SelectFilterRemote :name="'customerIds'" :options="customerOptions"
                                                @search="searchCustomers"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div class="col-md-3 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_SUPPLIER') }}</label>
                            <SelectFilterRemote :name="'supplierIds'" :options="comboSupplier"
                                                @search="searchSuppliers"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div class="col-md-3 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_PRODUCT') }}</label>
                            <SelectFilterRemote :name="'productIds'" :options="productsCombo"
                                                @search="handleSearchProducts"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div class="col-md-3 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_AGENT') }}</label>
                            <SelectFilterRemote :name="'agentIds'" :options="userOptions"
                                                @search="searchUsers"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                    </div>
                    <div class="row g-9 mb-8">
                        <div class="col-md-3 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_UNIDAD') }}</label>
                            <SelectFilterRemote :name="'unidadIds'" :options="comboUnidad"
                                                @search="searchUnidad"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div class="col-md-3 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_FAMILY') }}</label>
                            <SelectFilterRemote :name="'familyIds'" :options="comboFamily"
                                                @search="searchFamily"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div class="col-md-3 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_CLASS') }}</label>
                            <SelectFilterRemote :name="'classIds'" :options="comboSubFamily"
                                                @search="searchSubFamily"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div class="col-md-3 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_LINE') }}</label>
                            <SelectFilterRemote :name="'lineIds'" :options="comboLine"
                                                @search="searchLinea"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <button v-show="isViewBtn2" type="submit" class="btn btn-success me-3" ref="btnFilters2_1" @click="selectionAction = 'excel'">
                            <span v-if="loading2_1" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            {{ $t('BUTTONS.GENERATE_EXCEL') }}
                        </button>
                        <button type="submit" class="btn btn-success" ref="btnFilters2" @click="selectionAction = 'report'">
                            <span v-if="loading2" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            {{ $t('BUTTONS.GENERATE_REPORT') }}
                        </button>
                    </div>
                </Form>
            </div>
        </div>
        <div class="card mb-3 mb-xl-3">
            <div
                class="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                :data-bs-target="`#${idCollapse3}`"
                aria-expanded="true"
                :aria-controls="idCollapse3"
            >
                <div class="card-title m-0">
                    <h3 class="fw-bolder ml-2 color-text">{{ $t('LABELS.VENTAS_CLIENT') }}</h3>
                </div>
            </div>
            <div class="collapse hide m-10" :id="idCollapse3">
                <Form as="el-form" @submit="searchSalesClient" :validation-schema="validationSchemaFilters3"  ref="formFilters3">
                    <div class="row g-9 mb-8">
                        <div class="col-md-3 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.REPORT') }}</label>
                            <Select v-model="form3" :name="'reportSales'" :options="typeReport3">

                            </Select>
                        </div>
                        <div class="col-md-3 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_CUSTOMER') }}</label>
                            <SelectFilterRemote :name="'customerIds'" :options="customerOptions"
                                                @search="searchCustomers"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div class="col-md-3 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_AGENT') }}</label>
                            <SelectFilterRemote :name="'agentIds'" :options="userOptions"
                                                @search="searchUsers"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div class="col-md-3 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.DATE') }}</label>
                            <DatePickerRange></DatePickerRange>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <button v-show="isViewBtn3" type="submit" class="btn btn-success me-3" ref="btnFilters3_1" @click="selectionAction = 'excel'">
                            <span v-if="loading3_1" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            {{ $t('BUTTONS.GENERATE_EXCEL') }}
                        </button>
                        <button type="submit" class="btn btn-success" ref="btnFilters3" @click="selectionAction = 'report'">
                            <span v-if="loading3" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            {{ $t('BUTTONS.GENERATE_REPORT') }}
                        </button>
                    </div>
                </Form>
            </div>
        </div>
        <div class="card mb-3 mb-xl-3">
            <div
                class="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                :data-bs-target="`#${idCollapse4}`"
                aria-expanded="true"
                :aria-controls="idCollapse4"
            >
                <div class="card-title m-0">
                    <h3 class="fw-bolder ml-2 color-text">{{ $t('LABELS.VENTAS_SALESX') }}</h3>
                </div>
            </div>
            <div class="collapse hide m-10" :id="idCollapse4">
                <Form as="el-form" @submit="searchSalesX" :validation-schema="validationSchemaFilters4"  ref="formFilters4">
                    <div class="row g-9 mb-8">
                        <div :class="isViewForm4 ? 'col-md-4' : 'col-md-6'" class="col-xs-12 fv-row">
                            <label>{{ $t('LABELS.REPORT') }}</label>
                            <Select v-model="form4" :name="'reportSales'" :options="typeReport4">
                            </Select>
                        </div>
                        <div :class="isViewForm4 ? 'col-md-4' : 'col-md-6'" class="col-xs-12 fv-row">
                            <label>{{ $t('LABELS.DATE') }}</label>
                            <DatePickerRange></DatePickerRange>
                        </div>
                        <div class="col-md-4 col-xs-12 fv-row" v-show="isViewForm4">
                            <label>{{ $t('LABELS.NAME_AGENT') }}</label>
                            <SelectFilterRemote :name="'agentIds'" :options="userOptions"
                                                @search="searchUsers"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <button type="submit" class="btn btn-success me-3" ref="btnFilters4_1" @click="selectionAction = 'excel'">
                            <span v-if="loading4_1" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            {{ $t('BUTTONS.GENERATE_EXCEL') }}
                        </button>
                        <button type="submit" class="btn btn-success" ref="btnFilters4" @click="selectionAction = 'report'">
                            <span v-if="loading4" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            {{ $t('BUTTONS.GENERATE_REPORT') }}
                        </button>
                    </div>
                </Form>
            </div>
        </div>
        <div class="card mb-3 mb-xl-3">
            <div
                class="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                :data-bs-target="`#${idCollapse5}`"
                aria-expanded="true"
                :aria-controls="idCollapse5"
            >
                <div class="card-title m-0">
                    <h3 class="fw-bolder ml-2 color-text">{{ $t('LABELS.VENTAS_COMPARACION') }}</h3>
                </div>
            </div>
            <div class="collapse hide m-10" :id="idCollapse5">
                <Form as="el-form" @submit="searchSalesComparativo" :validation-schema="validationSchemaFilters5"  ref="formFilters5">
                    <div class="row g-9 mb-8">
                        <div :class="isViewForm5 ? 'col-md-4' : 'col-md-6'" class="col-xs-12  fv-row">
                            <label>{{ $t('LABELS.REPORT') }}</label>
                            <Select v-model="form5" :name="'reportSales'" :options="typeReport5">
                            </Select>
                        </div>
                        <div class="col-md-4 col-xs-12 fv-row" v-show="isViewForm5">
                            <label>{{ $t('LABELS.NAME_CUSTOMER') }}</label>
                            <SelectFilterRemote :name="'customerIds'" :options="customerOptions"
                                                @search="searchCustomers"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div :class="isViewForm5 ? 'col-md-4' : 'col-md-6'" class="col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_AGENT') }}</label>
                            <SelectFilterRemote :name="'agentIds'" :options="userOptions"
                                                @search="searchUsers"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                    </div>
                    <div class="row g-9 mb-8">
                        <div class="col-md-6 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.DATE') }}</label>
                            <DatePickerRange nameDateStart="startAnterior" nameDateEnd="endAnterior"></DatePickerRange>
                        </div>
                        <div class="col-md-6 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.DATE') }}</label>
                            <DatePickerRange></DatePickerRange>
                        </div>
                    </div>
                    <div class="row g-9 mb-8">
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_FAMILY') }}</label>
                            <SelectFilterRemote :name="'familyIds'" :options="comboFamily"
                                                @search="searchFamily"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_CLASS') }}</label>
                            <SelectFilterRemote :name="'classIds'" :options="comboSubFamily"
                                                @search="searchSubFamily2"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_LINE') }}</label>
                            <SelectFilterRemote :name="'lineIds'" :options="comboLine"
                                                @search="searchLinea2"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <button type="submit" class="btn btn-success me-3" ref="btnFilters5_1" @click="selectionAction = 'excel'">
                            <span v-if="loading5_1" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            {{ $t('BUTTONS.GENERATE_EXCEL') }}
                        </button>
                        <button type="submit" class="btn btn-success" ref="btnFilters5" @click="selectionAction = 'report'">
                            <span v-if="loading5" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            {{ $t('BUTTONS.GENERATE_REPORT') }}
                        </button>
                    </div>
                </Form>
            </div>
        </div>
        <div class="card mb-3 mb-xl-3">
            <div
                class="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                :data-bs-target="`#${idCollapse6}`"
                aria-expanded="true"
                :aria-controls="idCollapse6"
            >
                <div class="card-title m-0">
                    <h3 class="fw-bolder ml-2 color-text">{{ $t('LABELS.VENTAS_AGENTX') }}</h3>
                </div>
            </div>
            <div class="collapse hide m-10" :id="idCollapse6">
                <Form as="el-form" @submit="searchSalesAgentX" :validation-schema="validationSchemaFilters6"  ref="formFilters6">
                    <div class="row g-9 mb-8">
                        <div :class="isViewForm7 ? 'col-md-3' : 'col-md-4'" class="col-xs-12  fv-row">
                            <label>{{ $t('LABELS.REPORT') }}</label>
                            <Select v-model="form6" :name="'reportSales'" :options="typeReport6">
                            </Select>
                        </div>
                        <div :class="isViewForm7 ? 'col-md-3' : 'col-md-4'" class="col-xs-12 fv-row">
                            <label>{{ $t('LABELS.DATE') }}</label>
                            <DatePickerRange></DatePickerRange>
                        </div>
                        <div :class="isViewForm7 ? 'col-md-3' : 'col-md-4'" class="col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_AGENT') }}</label>
                            <SelectFilterRemote :name="'agentIds'" :options="userOptions"
                                                @search="searchUsers"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div v-show="isViewForm7" class="col-md-3 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_LOCATION') }}</label>
                            <Select :name="'typeLocation'" :options="typeLocation" :defaultValue="true">
                            </Select>
                        </div>
                    </div>
                    <div v-show="isViewForm6" class="row g-9 mb-8">
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_FAMILY') }}</label>
                            <SelectFilterRemote :name="'familyIds'" :options="comboFamily"
                                                @search="searchFamily"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_CLASS') }}</label>
                            <SelectFilterRemote :name="'classIds'" :options="comboSubFamily"
                                                @search="searchSubFamily3"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_LINE') }}</label>
                            <SelectFilterRemote :name="'lineIds'" :options="comboLine"
                                                @search="searchLinea3"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <button v-show="isViewBtn6" type="submit" class="btn btn-success me-3" ref="btnFilters6_1" @click="selectionAction = 'excel'">
                            <span v-if="loading6_1" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            {{ $t('BUTTONS.GENERATE_EXCEL') }}
                        </button>
                        <button type="submit" class="btn btn-success" ref="btnFilters6" @click="selectionAction = 'report'">
                            <span v-if="loading6" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            {{ $t('BUTTONS.GENERATE_REPORT') }}
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>
<i18n>
{
    "es": {
        "LABELS": {
            "VENTAS_PERIODO": "Reporte de ventas por período",
            "VENTAS_ARTICULO": "Reporte de ventas por artículo",
            "VENTAS_CLIENT": "Reporte de ventas por cliente",
            "VENTAS_SALESX": "Reporte de ventas generales por ...",
            "VENTAS_COMPARACION": "Reporte de ventas por comparación",
            "VENTAS_AGENTX": "Ventas por agentes",
            "COMPRAS_PERIODO": "Reporte de compras por período",
            "COMPRAS_ARTICULO": "Reporte de compras por articulo",
            "NAME_LOCATION" : "Tipo de locacion",
            "NAME_CUSTOMER": "Clientes",
            "NAME_AGENT": "Agente",
            "NAME_UNIDAD": "Unidad",
            "NAME_CASHIER": "Caja",
            "NAME_COMPLETO": "Estatus",
            "NAME_SUPPLIER": "Proveedor",
            "NAME_PRODUCT": "Producto",
            "NAME_FAMILY": "Familia",
            "NAME_CLASS": "Clase",
            "NAME_LINE": "Línea",
            "NAME_FOLIO": "Folio",
            "DATE": "Fecha",
            "TAX": "Impuestos",
            "TRANSACTION": "Tipo de transacción",
            "REPORT": "Reporte por:"
        },
        "BUTTONS": {
            "CLEAN_FILTERS_FORM": "Limpiar",
            "GENERATE_REPORT": "Generar reporte",
            "GENERATE_EXCEL": "Generar excel"
        }
    }
}
</i18n>

<style lang="scss">
    .color-text{
        color: rgba(0,0,0,.5);
    }
</style>
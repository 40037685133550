<script setup>
import * as echarts from 'echarts';
import {computed, onMounted, ref} from "vue";
import ApiService from "@/core/services/ApiService";
import {getModule} from "vuex-module-decorators";
import NewProductEntryModule from "@/store/modules/productEntry/modules/new-product-entry";
import SelectFilter from "@/views/graficas/asset/FilterSelect.vue";

const chartRef = ref(null);
let myChart = null;

const startDate = ref("");
const endDate = ref("");

const datos = ref([]);

const selectedProduct = ref(null);

const moduleProduct = getModule(NewProductEntryModule);
const productsCombo = computed(() => moduleProduct.productOptions);

const formatDate = (date) => {
    if (!date) return '';
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

const subtractDays = () => {
    const newDate = formatDate(new Date());

    const fecha = new Date(newDate);
    fecha.setDate(fecha.getDate() - 30);

    return formatDate(fecha);
};

onMounted(async () => {
    resetFilterDateInitial();

    let url = `Graphics/ProductXTime?StartDate=${startDate.value}&EndDate=${endDate.value}&ProductId=0eb376fa-a97e-4cc2-ba4d-20a7443c5967`

    const res = await ApiService.query(url);

    datos.value = res.data.map(x => [x.fecha, x.total]);

    if (chartRef.value) {
        myChart = echarts.init(chartRef.value);
        myChart.setOption(option.value);
    }
});

const option = ref({
    title: {
        text: "Datos del producto",
        subtext: `${subtractDays()} - ${formatDate(new Date())}`,
        left: "center",
        textStyle: {
            fontSize: 15,
            fontWeight: 'bold'
        }
    },
    tooltip: {
        trigger: 'axis',
        textStyle: {
            fontSize: 10,
            fontWeight: 'normal',
        },
        formatter: (params) => {
            const dataPoint = params[0];
            return `
                <b>${dataPoint.axisValue}</b><br/>
                Total: ${dataPoint.data[1]}
            `;
        }
    },
    xAxis: {
        type: 'category',
        boundaryGap: false
    },
    yAxis: {
        type: 'value',
        boundaryGap: [0, '30%']
    },
    visualMap: {
        type: 'piecewise',
        show: false,
        dimension: 0,
        seriesIndex: 0,
        pieces: [
            {
                gt: 1,
                lt: 3,
                color: 'rgba(0, 0, 180, 0.4)'
            },
            {
                gt: 5,
                lt: 7,
                color: 'rgba(0, 0, 180, 0.4)'
            }
        ]
    },
    series: [
        {
            type: 'line',
            smooth: 0.6,
            symbol: 'none',
            lineStyle: {
                color: '#5470C6',
                width: 5
            },
            markLine: {
                symbol: ['none', 'none'],
                label: { show: false },
                data: [{ xAxis: 1 }, { xAxis: 3 }, { xAxis: 5 }, { xAxis: 7 }]
            },
            areaStyle: {},
            data: datos
        }
    ]
});

const resetFilterDateInitial = () => {
    endDate.value = formatDate(new Date());

    const startDateValue = new Date(endDate.value);
    startDateValue.setDate(startDateValue.getDate() - 30);

    startDate.value = formatDate(startDateValue);
};

const updateChart = async () => {
    let url = `Graphics/ProductXTime?StartDate=${startDate.value}&EndDate=${endDate.value}&ProductId=${selectedProduct.value}`

    const res = await ApiService.query(url);

    const updatedData = res.data.map(x => [x.fecha, x.total]);

    option.value.series[0].data = updatedData;
    option.value.title.subtext = `${startDate.value} - ${endDate.value}`;

    if (myChart) {
        myChart.setOption(option.value);
    }
};

const handleSearchProducts = (name) => {
    if(name.trim().length >= 3){
        moduleProduct.SEARCH_PRODUCTS(name);
    }
}
</script>

<template>

    <div class="conjunto">
        <div class="g-productTime" ref="chartRef"></div>
        <div class="btnG">
            <button @click="updateChart" class="update-button">Actualizar</button>
            <div class="date-picker">
                <label>
                    Fecha Inicio:
                    <input class="styled-date-input" type="date" v-model="startDate" />
                </label>
                <label>
                    Fecha Fin:
                    <input class="styled-date-input" type="date" v-model="endDate" />
                </label>
                <label class="containerSelect">
                    Producto
                    <SelectFilter class="filter"
                                  :name="'productIds'"
                                  :options="productsCombo"
                                  v-model="selectedProduct"
                                  @search="handleSearchProducts"
                                  :placeholder="'Filtro por nombre'"
                    />
                </label>
            </div>
        </div>
    </div>
</template>

<style scoped>
.conjunto {
    position: relative;
    width: 100%;
}

.styled-date-input {
    padding: 5px;
    font-size: 10px;
    border: 2px solid #0BB7AF;
    border-radius: 5px;
    outline: none;
    transition: all 0.3s ease;
}

label{
    display: flex;
    flex-direction: column;
}

.containerSelect{
    width: 300px;
    margin-top: 17px;
}

.g-productTime {
    width: 100%;
    height: 400px;
}

.btnG{
    margin-left: 30px;
    position: absolute;
    bottom: -30px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.date-picker {
    display: flex;
    gap: 30px;
    justify-content: center;
    align-items: center;
}

.date-picker label {
    font-size: 12px;
}

.update-button {
    background-color: #007BFF;
    height: min-content;
    margin-top: 14px;
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
}

.update-button:hover {
    background-color: #0056b3;
}
</style>
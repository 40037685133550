import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import store from "@/store";
import ApiService from "@/core/services/ApiService";

@Module({ namespaced: true, name: 'InvoiceModule', store: store })
class InvoiceModule extends VuexModule {
    selectedId: string | null = null;
    price: number | null = null;

    // Mutation para actualizar el ID
    @Mutation
    setSelectedId(id: string) {
        this.selectedId = id;
    }

    // Mutation para actualizar el precio
    @Mutation
    setPrice(price: number) {
        this.price = price;
    }

    @Action
    async updateSelectedId(id: string) {
        this.context.commit('setSelectedId', id);
        await this.fetchPrice(id);
    }

    @Action
    async fetchPrice(id: string) {
        try {
            const url = `Invoice/DebtInvoice/${id}`
            const res = await ApiService.query(url);

            const price = res.data;
            this.context.commit('setPrice', price);
        } catch (error) {
            console.error("Error al obtener el precio:", error);
            this.context.commit('setPrice', null);
        }
    }

    get getSelectedId(): string | null {
        return this.selectedId;
    }

    // Getter para acceder al precio
    get getPrice(): number | null {
        return this.price;
    }
}

export default InvoiceModule;
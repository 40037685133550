export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelación de factura"])},
        "FIELDS": {
          "MOTIVO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo de cancelación"])},
          "RFCRESEPTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RFC del receptor"])},
          "TOTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
          "FOLIOSUST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folio de sustitución"])},
          "PRODUCT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producto"])},
          "START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de inicio"])},
          "END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de fin"])},
          "PAGO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Método de pago"])},
          "SALDO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo anterior"])}
        },
        "PH": {
          "PRODUCT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producto"])},
          "START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de inicio"])},
          "END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de fin"])}
        }
      }
    }
  })
}

import Modules from '@/store/enums/Modules';
import store from '@/store';
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import TableBillCreatedState from '../../models/TableBillCreatedState';
import BillCreateRow from '@/models/bill/BillCreatedRow';
import FiltersBillCreated from '@/models/bill/filters/FiltersBillCreated';
import PaginationRequest from '@/models/general/PaginationRequest';
import { Mutations } from '@/store/commons/MutationsTable';
import { SaveValuesToStore } from '@/core/shared/AssingProps';
import { Actions } from '@/store/commons/ActionsTable';
import BillService from '@/core/services/bill/BillService';
import TableBillCreatedService from '@/core/services/bill/interfaces/TableBillCreatedService';

@Module({dynamic: true, store, namespaced: true, name: Modules.TableBillCreated})
export default class TableBillCreatedModule extends VuexModule implements TableBillCreatedState {
    totalRecords = 0;
    pagination = {
        currentPage: 1
        , pageSize: 10
    } as PaginationRequest;
    loading = false;
    records = [] as BillCreateRow[]
    filters = {
        clientName: ''
        , folio: ''//numBill - folio venta
        , startDate: ''
        , endDate: ''
        , rfc: ''
        , saleFolio: ''
        //, isSend : null
        
    } as FiltersBillCreated;
    
    @Mutation
    [Mutations.SET_VALUE_LOADING](value: boolean){
        this.loading = value;
    }
    @Mutation
    [Mutations.SET_TABLE_DATA](table: BillCreateRow[]){
        this.records = table;
    }
    @Mutation
    [Mutations.SET_PAGINATION](pagination: unknown){
        SaveValuesToStore(this.pagination, pagination);
    }
    @Mutation
    [Mutations.SET_FILTERS](filters: unknown){
        SaveValuesToStore(this.filters, filters, true);
    }
    @Mutation
    [Mutations.SET_TOTAL_RECORDS](totalRecords: number){
        this.totalRecords = totalRecords;
    }

     /**
     * Se obtienen los datos de la tabla de facturas creadas mediante la paginación
     */
     @Action
     async [Actions.SEARCH_TABLE_DATA](){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
         const resp = (await (service.searchTableBillCreated(this.pagination, this.filters)
             .finally(() => this.context.commit(Mutations.SET_VALUE_LOADING, false)))).data ??
             {
                 data: [] as BillCreateRow[]
                , totalPages: 0
                , currentPage: this.pagination.currentPage
             }
         const data = resp.data.map((d) => { 
             const cancellationDate = (!d.cancellationDate || d.cancellationDate == '') ? 'N/a' : d.cancellationDate;
             const date = (!d.invoiceDate || d.invoiceDate == '') ? 'sin registro' : d.invoiceDate;
             return {
                 ...d
                 , cancellationDate: cancellationDate
                 , date: date
             }
         })
         this.context.commit(Mutations.SET_TABLE_DATA, data);
         this.context.commit(Mutations.SET_PAGINATION,{ currentPage: resp.currentPage });
         this.context.commit(Mutations.SET_TOTAL_RECORDS, resp.totalPages);
    }
 
     /**
        * Realiza un busqueda con la nueva paginación
        * @param pagination paginación de la tabla
        */
     @Action
     async [Actions.CHANGE_PAGE](pagination: unknown){
           this.context.commit(Mutations.SET_PAGINATION, pagination);
           this.context.dispatch(Actions.SEARCH_TABLE_DATA);
     }
 
     /**
      * Filtros que se aplican y despues se hace la busqueda de los datos
      * @param filters filtros nuevos
      */
     @Action
     async [Actions.UPDATE_FILTERS](filters: unknown){
         //aqui se cambia el filtro y se buscan los datos de nuevo de la tabla
         this.context.commit(Mutations.SET_FILTERS, filters);
         this.context.commit(Mutations.SET_PAGINATION, {
           currentPage: 1 //si cambiamos filtros buscamos sobre la pagina uno
         });
         this.context.dispatch(Actions.SEARCH_TABLE_DATA);
     }
} 

const service = new BillService() as TableBillCreatedService;
<script setup>
import * as echarts from 'echarts';
import { onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";

const chartRef = ref(null);
let myChart = null;

const startDate = ref("");
const endDate = ref("");

const nombres = ref(null);
const totales = ref(null);

const formatDate = (date) => {
    if (!date) return '';
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

const subtractDays = () => {
    const newDate = formatDate(new Date());

    const fecha = new Date(newDate);
    fecha.setDate(fecha.getDate() - 30);

    return formatDate(fecha);
};

onMounted(async() => {
    resetFilterDateInitial();

    let url = `Graphics/AgentSale?StartDate=${startDate.value}&EndDate=${endDate.value}`

    const res = await ApiService.query(url);

    nombres.value = res.data.map(x => x.agent);
    totales.value = res.data.map(x => x.total);

    if (chartRef.value) {
        myChart = echarts.init(chartRef.value);
        myChart.setOption(option.value);
    }
});

const option = ref({
    title: {
        text: "Ventas por agentes",
        subtext: `${subtractDays()} - ${formatDate(new Date())}`,
        left: "center",
        textStyle: {
            fontSize: 15,
            fontWeight: 'bold'
        }
    },
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        },
        textStyle: {
            fontSize: 10,
            fontWeight: 'normal',
        },
        formatter: '{b}: ${c}'
    },
    xAxis: {
        type: 'category',
        data: nombres
    },
    yAxis: {
        type: 'value'
    },
    series: [
        {
            data: totales,
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.2)'
            }
        }
    ]
});

const resetFilterDateInitial = () => {
    endDate.value = formatDate(new Date());

    const startDateValue = new Date(endDate.value);
    startDateValue.setDate(startDateValue.getDate() - 30);

    // Asignar las fechas a las variables
    startDate.value = formatDate(startDateValue);
};

const updateChart = async () => {
    let url = `Graphics/AgentSale?StartDate=${startDate.value}&EndDate=${endDate.value}`

    const res = await ApiService.query(url);

    // Simula datos actualizados para las fechas seleccionadas
    const updatedData = res.data.map(x => x.total);
    const updatedCategories = res.data.map(x => x.agent);

    option.value.series[0].data = updatedData;
    option.value.xAxis.data = updatedCategories;
    option.value.title.subtext = `${startDate.value} - ${endDate.value}`;

    if (myChart) {
        myChart.setOption(option.value);
    }
};
</script>

<template>
    <div class="conjunto">
        <div class="g-salesAgent" ref="chartRef"></div>
        <div class="btnG">
            <button @click="updateChart" class="update-button">Actualizar Gráfico</button>
            <div class="date-picker">
                <label>
                    Fecha Inicio:
                    <input class="styled-date-input" type="date" v-model="startDate" />
                </label>
                <label>
                    Fecha Fin:
                    <input class="styled-date-input" type="date" v-model="endDate" />
                </label>
            </div>
        </div>
    </div>
</template>

<style scoped>
.conjunto {
    position: relative;
}

.styled-date-input {
    padding: 5px;
    font-size: 10px;
    border: 2px solid #0BB7AF;
    border-radius: 5px;
    outline: none;
    transition: all 0.3s ease;
}

.g-salesAgent {
    width: 700px;
    height: 400px;
    //background-color: #0BB7AF;
}

.btnG{
    margin-left: 70px;
    position: absolute;
    bottom: 5px;
    display: flex;
    gap: 20px;
}

.date-picker {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.date-picker label {
    font-size: 12px;
}

.update-button {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
}

.update-button:hover {
    background-color: #0056b3;
}
</style>


import { useField } from "vee-validate";
import {computed, defineComponent} from "vue";
export default defineComponent({
    props:{
        name: {
            type: String
            , required: true
        }, 
        disabled: {
            type: Boolean
            , default: false
        },
        placeholder: {
            type: String
            , default: ''
        },
        min: {
            type: Number
            , default: 0
        },
        max: {
            type: Number
            , default: 999
        },
        step:{
            type: Number
            , default: 1
        },
        precision: {
            type: Number
            , default: 0
        },
        decimal: {
            type: Boolean,
            default: false
        }
    },
    emits: ['change'],
    setup(props){
       
        const { value, errorMessage } = useField(props.name);

        const computedPrecision = computed(() => {
            return props.decimal ? props.precision : 0;
        });

        const clear = () => {
            value.value = 0
        } 
        return {
            value
            , errorMessage
            , clear
            , computedPrecision
        }
    }
})

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_el_form_item, { error: _ctx.errorMessage }, {
      default: _withCtx(() => [
        _createVNode(_component_el_input_number, {
          modelValue: _ctx.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
          class: "w-100",
          "validate-event": false,
          min: _ctx.min,
          max: _ctx.max,
          disabled: _ctx.disabled,
          step: _ctx.step,
          precision: _ctx.computedPrecision,
          placeholder: _ctx.placeholder,
          onChange: _cache[1] || (_cache[1] = (newValue) => _ctx.$emit('change', newValue))
        }, null, 8, ["modelValue", "min", "max", "disabled", "step", "precision", "placeholder"])
      ]),
      _: 1
    }, 8, ["error"])
  ]))
}
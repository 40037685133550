<script setup>

import {onMounted, ref} from "vue";
import ReportArticles from "@/views/reports/ReportArticle.vue";
import ReportSale from "@/views/reports/ReportSales.vue";
import ReportPurchase from "@/views/reports/ReportPurchases.vue";
import PDFViewer from "@/components/modals/general/PDFViewer.vue";

import {toggleCollapse} from "@/core/helpers/dom";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";

    //toggleCollapse("#" + idCollapse.value);

    onMounted(() => {
        setCurrentPageBreadcrumbs("Reportes", ["Reportes y Gráficas"]);
    });

    const openPDF = ref(null);
    const pdf = ref("");
    const namePdf = ref("");

    const emitPdf = (base64, nombre) => {
        pdf.value = "data:application/pdf;base64," + base64;
        namePdf.value = nombre;
        handleViewRecib();
    };

    const handleViewRecib = async () => {
        if(openPDF.value) {
            openPDF.value.click();
        }
    }

</script>

<template>
    <section id="advance-search">
        <div class="margin-aplicate">
            <h1 class="fs-2">Reporte de Ventas</h1>
            <ReportSale @emitData="emitPdf"/>
        </div>
        <div class="margin-aplicate">
            <h1 class="fs-2">Reporte de Compras</h1>
            <ReportPurchase @emitData="emitPdf"/>
        </div>
        <div>
            <h1 class="fs-2">Reporte por Articulo</h1>
            <ReportArticles @emitData="emitPdf"/>
        </div>
    </section>
    <PDFViewer :base64="pdf" :titulo="namePdf" :fileName="namePdf" ></PDFViewer>
    <button
        hidden
        ref="openPDF"
        class="btn btn-primary er fs-6 "
        data-bs-toggle="modal"
        data-bs-target="#viewPDF"
    >
        Reporte
    </button>
</template>

<style lang="scss">

.margin-aplicate{
    margin-bottom: 50px;
}
</style>
export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "PLACE_HOLDERS": {
          
        },
        "LABELS": {
          "NUM_BILL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° Factura"])},
          "DATE_CREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha Creación"])},
          "CLIENT_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre Cliente"])},
          "RFC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R.F.C."])},
          "SALE_FOLIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folio de Venta"])},
          "ADVANCE_SERACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros de busqueda de facturas"])}
        },
        "BUTTONS": {
          "SEND_ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar todos"])},
          "SEARCH_BILLS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar Facturas"])}
        },
        "TOOLTIPS": {
          "SEND_ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar todas las facturas de esta pagina"])},
          "DOWNLOAD_DOCS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar factura"])},
          "SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enviar factura"])}
        },
        "MESSAGES": {
          "NO_FOUND_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontro facturas con los filtros indicados"])}
        }
      }
    }
  })
}

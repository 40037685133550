<script>
import {computed, defineComponent, onBeforeMount, onMounted, ref} from "vue";
import Select from "@/components/forms/Select.vue";
import {Form} from "vee-validate";
import * as Validations from "yup";
import InputNumber from "@/components/forms/InputNumber.vue";
import DynamicContentModal from "@/components/modals/general/DynamicContentModal.vue";
import {hideModalById} from "@/core/helpers/dom";
import Swal from "sweetalert2";
import {getMessageError} from "@/core/helpers/messageFromPulmeros";
import SwalMessageService from "@/core/services/SwalMessageService";
import ApiService from "@/core/services/ApiService";

import { useStore } from 'vuex';
import { getModule as getInvoiceModule } from 'vuex-module-decorators';
import InvoiceModule from "@/store/modules/bill/modules/complemento-bill/InvoiceModule";

export default defineComponent({
    components: {
        DynamicContentModal,
        InputNumber,
        Select,
        Form,
    },
    setup() {
        const store = useStore();
        const invoiceStore = getInvoiceModule(InvoiceModule, store);

        const form = ref();
        const formCancel = ref();
        const btnForm = ref();
        const loading = ref(false);
        const saldoP = computed(() => invoiceStore.getPrice);
        const messageService = new SwalMessageService();
        let paymentTypes = ref([]);
        const comboPaymentTypes = computed(() => paymentTypes.value);

        onBeforeMount(async () => {
            await getComboOptions("WayToPay", paymentTypes);
        });

        const getComboOptions = async (resource, container, parentId = "") => {
            let parentParam = parentId.trim() != "" ? `${parentId}/` : "";
            let url = `${resource}/${parentParam}combo`;
            ApiService.get(url).then(res => {
                container.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService, "MessageError");
            });
        }

        const validationSchema = Validations.object().shape({
            metodoPago: Validations.string().default('').required("Selecciona una opción")
            , monto: Validations.number().required("Ingrese el monto")
                .test("valorMax", "El monto debe ser menor al saldo", (value) => {
                    return value <= saldoP.value;
                })
        })

        const enviarForm = async (data) => {
            let obj = {
                id: invoiceStore.getSelectedId,
                folio: "13",
                serie: "A",
                uuid: "37af7dd4-2ff8-4d22-812a-e06edc15db45",
                monto: data.monto,
                saldoAnterior: data.saldoAnt,
                formaPago: data.metodoPago,
            }

            await ApiService.post("Invoice/ParcialidadesBilling", obj)
                .catch(reject => {
                getMessageError(reject, messageService);
            });

            messageService.success(`Se timbro correctamente el complemento de pago`);
        }

        const submitForm = async (filters) => {
            resetForm()

            Swal.fire({
                title: '¿Está seguro de generar el complemento de pago?',
                text: "Se procederá a timbrar el complemento de pago",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#000000',
                cancelButtonColor: '#7E829',
                confirmButtonText: 'Si, Timbrar',
                cancelButtonText: 'Cancelar',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await enviarForm(filters);
                }
            });

            hideModalById("#ParcialidadBillingModal");
        }

        const resetForm = () => {
            formCancel.value.resetForm();
            form.value = null;
        }

        const precio = ref(12)

        return {
            formCancel,
            validationSchema,
            comboPaymentTypes,
            submitForm,
            btnForm,
            loading,
            form,
            saldoP,
            precio
        }
    }
})
</script>

<template>
    <section>
        <DynamicContentModal :targetId="'ParcialidadBillingModal'" :width="600">
            <template v-slot:header>
                <div class="mb-5 text-center">
                    <h1 class="mb-3">
                        <span>{{ 'Complemento de pago' }}</span>
                    </h1>
                </div>
            </template>

            <Form as="el-form" :validation-schema="validationSchema" @submit="submitForm" ref="formCancel">
                <div class="row g-9 mb-8">
                    <div class="col-md-12 col-xs-12 fv-row">
                        <label>{{ $t('FIELDS.PAGO') }}</label>
                        <Select :name="'metodoPago'" :options="comboPaymentTypes" />
                    </div>
                </div>
                <div class="row g-9 mb-8">
                    <div class="col-md-6 col-xs-12 fv-row">
                        <label>{{ $t('FIELDS.SALDO') }}</label>
                        <Select v-model="saldoP" :name="'saldoAnt'" disabled/>
                    </div>
                    <div class="col-md-6 col-xs-12 fv-row">
                        <label>{{ $t('FIELDS.TOTAL') }}</label>
                        <InputNumber :name="'monto'" :decimal="true" :precision="2" :step="0.01"/>
                    </div>
                </div>
                <div>
                    <button type="submit" class="btn btn-lg btn-primary float-end" ref="btnForm">
                        <span v-if="loading" class="spinner-border spinner-border-sm me-1" role="status"
                              aria-hidden="true"></span>
                        {{ "Timbrar complemento de pago" }}
                    </button>
                </div>
            </Form>
        </DynamicContentModal>
    </section>
</template>

<style lang="scss">
.itemControl {
    width: 272px;
}

.largeItemControl {
    width: 553px;
}

.tamano {
    width: 573px !important;
}
</style>

<i18n>
{
    "es": {
        "TITLE": "Cancelación de factura",
        "FIELDS": {
            "MOTIVO": "Motivo de cancelación",
            "RFCRESEPTOR": "RFC del receptor",
            "TOTAL": "Monto",
            "FOLIOSUST": "Folio de sustitución",
            "PRODUCT": "Producto",
            "START": "Fecha de inicio",
            "END": "Fecha de fin",
            "PAGO": "Método de pago",
            "SALDO": "Saldo anterior"
        },
        "PH": {
            "PRODUCT": "Producto",
            "START": "Fecha de inicio",
            "END": "Fecha de fin"
        }
    }
}
</i18n>
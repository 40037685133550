import OptionSelect from "@/models/shared/OptionSelect"
import { translate } from "../plugins/i18n"

enum CustomerType {
    Public =  0
    , Wholesale = 1
    , Buyer = 2//Aluminiero
}

/**
 * Obtiene los valores del enumerador
 */
const possibleValues = Object.values(CustomerType)
    .filter((v) => !isNaN(Number(v)))

function getOptions(): OptionSelect[] {
    return [
        {
            id: CustomerType.Public
            , name: translate("ENUMS.CUSTOMER_TYPES.PUBLIC")
        },
        {
            id: CustomerType.Wholesale
            , name: translate("ENUMS.CUSTOMER_TYPES.WHOLESALE")
        },
        {
            id: CustomerType.Buyer
            , name: translate("ENUMS.CUSTOMER_TYPES.BUYER")
        }
    ]
}
export { possibleValues, getOptions , CustomerType}